import axios from "axios";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaRegTrashAlt, FaSearch, FaTimesCircle } from "react-icons/fa";
import { BASE_UPLOAD_URL, BASE_URL } from "../../config/config";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import "./search-bar.styles.css";

const SearchBarComponent = ({
  searchRoute="admin/filterParentsAndProviders",
  divClasses = "py-2",
  additionalQuery="",
  triggerCloseOnSelection=false,
  showTermAfterSelection=false,
  returnAction,
  returnSearchTerm,
  searchPlaceholder = "Search Parent/Provider",
  doNotShowIfNoResults = false,
  renderLoopData,
  value_key,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [showSearch, setShowSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  // const [showResults, setShowResults] = useState(true);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResults([]);
    } else {
      returnSearchTerm && returnSearchTerm(searchTerm);
    }
  }, [searchTerm]);

  const handleSearchUsersTerm = async (search_term) => {
    try {
      if (search_term === "") return;
      if (search_term.length < 3) return;
      const response = await axios.get(
        `${BASE_URL}/${searchRoute}?search_term=${search_term}&${additionalQuery}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // console.log("parents and provider response", response);
        setSearchResults(response.data.results);
        // if (!showSearch) setShowSearch(true);
      }
    } catch (e) {
      if (e.response) {
        console.log(e.response);
        console.log(e.response.data.message);
      } else {
        console.log(e);
      }
    }
  };

  const handleSelectItem = (selected_item) => {
    setSelectedItem(selected_item);
    // Return selected item to parent component
    returnAction && returnAction(selected_item, selected_item[value_key]?selected_item[value_key]:null, searchTerm);
    triggerCloseOnSelection && !showTermAfterSelection && setSearchTerm("");
    if (showTermAfterSelection) {
      setSearchResults([]);
      setSearchTerm(selected_item[value_key]);
    }
    // setShowSearch(false)
  }

  const handleHideSearchList = () => {
    setSearchResults([]);
  }

  const onChangeSearchTerm = (search_term) => {
    setSearchTerm(search_term);
    handleSearchUsersTerm(search_term)
  }

  return (
    <div className={`w-100 ${divClasses}`}>
      <div className='w-100' style={{ position: "relative" }}>
        <Form.Control
          type='text'
          value={searchTerm}
          onChange={(e) => onChangeSearchTerm(e.target.value)}
          placeholder={`${searchPlaceholder ? searchPlaceholder :'Search Parent/Provider'}`}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
          }}
        >
          <FaTimesCircle
            className='text-danger pointer-cursor'
            onClick={() => setSearchTerm("")}
            title="Hide search results"
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "30px",
            transform: "translateY(-50%)",
          }}
        >
          <FaRegTrashAlt
            className='text-danger pointer-cursor'
            onClick={handleHideSearchList}
            title="Clear search data"
          />
        </div>
        <div
          className={`${searchTerm === "" ? "d-none" : (searchResults.length === 0 && doNotShowIfNoResults) ? "d-none" :"d-block" } bg-light w-100 py-2 px-3 mb-3 border boder-2 box-shadow`}
          // className={`${searchTerm === "" && !showSearch ? "d-none" : "d-block" } bg-light w-100 py-2 px-3 mb-3 border boder-2 box-shadow`}
          style={{ position: "absolute", zIndex: "10", minHeight: "50px", maxHeight: "200px", overflowY: "scroll" }}
        >
          {searchResults.length === 0 && (
              <div className='mt-3'>No Results Found.</div>
          )}
          {searchResults.length > 0 &&
            searchResults.map((result, idx) => (
              <div
                key={idx}
                className={`${
                  selectedItem !== null &&
                  result._id === selectedItem._id &&
                  "selected-card"
                } w-100 my-2 search-results-card p-2 border pointer-cursor d-flex`}
                onClick={() => handleSelectItem(result)}
              >
                {renderLoopData ? renderLoopData(result) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <ProfileAvatar
                        diameter={40}
                        imageUrl={
                          result.profile_pic_url &&
                          BASE_UPLOAD_URL + result.profile_pic_url
                        }
                      />
                    </div>
                    <div className='ms-2 d-flex flex-column justify-content-center align-items-start'>
                      <div>{result.name}</div>
                      <div>{result.email}</div>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>


    </div>
  );
};

export default SearchBarComponent;
