import { useState, useMemo } from "react";
import { useCookies } from "react-cookie";
import { motion } from "framer-motion";
import jwtDecode from "jwt-decode";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Image,
  InputGroup,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FaInfo, FaPlusCircle, FaTimesCircle } from "react-icons/fa";

import {
  addCreditsToUser,
  createPaymentIntentForUserCredits,
  giveAwayFreeCreditsToUser,
} from "../../API/creditApiCallls";
import SearchBarComponent from "../../components/SearchBarComponent/SearchBarComponent";
import {
  useFetchCreditDetails,
  useFetchCreditTransactions,
} from "../../hooks/apiCallHooks";
import SelectCardList from "../../components/SelectCardList/SelectCardList";
import CheckoutForm from "../../components/Payments/CheckoutForm";
import { STRIPE_PUBLISHABLE_KEY } from "../../config/config";
import transImage from "../../assets/img/icons/payment.png";
import transGiftImage from "../../assets/img/icons/money_gift.png";
import transCreditImage from "../../assets/img/icons/credit_money.png";
// import creditImage from "../../assets/img/icons/money.png";
import creditImage from "../../assets/img/icons/dollar.png";

import "./credit-balance.styles.css";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CreditBalance = () => {
  const [cookies] = useCookies("blind_weight");
  const token =
    cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
  const decoded = jwtDecode(token);
  const userID = decoded ? decoded.id : null;
  const userData = decoded ? decoded : null;
  const [currentUserID, setCurrentUserID] = useState(userID);
  // Use state to store the data you fetched
  const [creditData, setCreditData] = useState(null);
  const [showUpdateCreditBalance, setShowUpdateCreditBalance] = useState(false);
  const [userCreditBalance, setUserCreditBalance] = useState("");
  const [updatingUserCreditBalance, setUpdatingUserCreditBalance] =
    useState(false);
  const [currentUserTransData, setCurrentUserTransData] = useState(null);

  const [clientSecret, setClientSecret] = useState(null);
  const [creditPrice, setCreditPrice] = useState(10);
  const [showCreditPaymentModal, setShowCreditPaymentModal] = useState(false);
  const [createPayIntentLoading, setCreatePayIntentLoading] = useState(false);

  const [giveAwayCreditAmount, setGiveAwayCreditAmount] = useState(10);
  const [giveAwayRemark, setGiveAwayRemark] = useState(
    "Give Away Free Credits"
  );
  const [showGiveAwayCreditModal, setShowGiveAwayCreditModal] = useState(false);
  const [loadingGiveAwayCreditModal, setLoadingGiveAwayCreditModal] =
    useState(false);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  // Use react-query's useQuery to fetch the data and cache it
  const {
    data: fetchedData,
    isLoading,
    error,
    refetch: refetchCreditData,
  } = useFetchCreditDetails(currentUserID);

  // Use useMemo to set the data in the state only when it's available or changes
  useMemo(() => {
    if (fetchedData && fetchedData.status) {
      setCreditData(fetchedData.userData);
    }
  }, [fetchedData]);

  const {
    data: creditTransData,
    isLoading: isLoadingCreditTrans,
    error: creditTransError,
  } = useFetchCreditTransactions(currentUserID);

  useMemo(() => {
    if (creditTransData && creditTransData.status) {
      console.log(creditTransData.userData);
      setCurrentUserTransData(creditTransData.userData);
    }
  }, [creditTransData]);

  const handleAddCreditToUser = async (e, user_id) => {
    e.preventDefault();
    if (!user_id) return;
    if (userData.user_access !== "admin" && createPayIntentLoading) return;
    let error_heading = "",
      error_text = "";
    if (!userCreditBalance) {
      error_heading = `${
        userData.user_access === "admin" ? "Enter" : "Select"
      } Credit Balance`;
      error_text = `Please ${
        userData.user_access === "admin" ? "enter" : "select"
      } credit amount to proceed`;
      Swal.fire({
        icon: "warning",
        title: error_heading,
        text: error_text,
      });
      return;
    }

    if (userData.user_access !== "admin") {
      // Show Payment modal so that parent/provider pays to add credit
      // createPaymentIntentForUserCredits
      setCreatePayIntentLoading(true);
      const data = await createPaymentIntentForUserCredits(
        user_id,
        userCreditBalance
      );
      if (data && data.status) {
        // console.log("payment intent created: ", data);
        setClientSecret(data.clientSecret);
        let price = data.price_data.unit_amount;
        price = parseFloat((price / 100).toFixed(2));
        // console.log("price: ", price)
        setCreditPrice(price);
        setShowCreditPaymentModal(true);
      } else {
        // console.log(data)
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message,
        });
      }
      setCreatePayIntentLoading(false);
      return;
    }

    if (userData.user_access === "admin") {
      const warning = await Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Do you Confirm to add credit balance to user?",
        showDenyButton: true,
        confirmButtonText: "Confirm",
        denyButtonText: "No",
      });

      if (!warning.isConfirmed) return;

      setUpdatingUserCreditBalance(true);
      const remark = "Free Credits";
      // Add credits to user
      const data = await addCreditsToUser(user_id, userCreditBalance, remark);
      if (data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
        });
        refetchCreditData();
        setUserCreditBalance("");
      } else {
        Swal.fire({
          icon: "error",
          text: "Error",
          text: data.message,
        });
      }
      setUpdatingUserCreditBalance(false);
    }
  };

  const handleShowGiveAwayFreeCreditsModal = () => {
    setShowGiveAwayCreditModal(true);
  };

  const handleGiveAwayCredits = async () => {
    if (loadingGiveAwayCreditModal) return;
    if (!giveAwayRemark || giveAwayRemark === "") {
      Swal.fire({
        icon: "warning",
        title: "Wait",
        text: "Please provide a remark",
      });
      return;
    } else if (giveAwayRemark.length < 3 || giveAwayRemark.length > 51) {
      Swal.fire({
        icon: "warning",
        title: "Remark too Short",
        text: "Remark should be between 3 to 50 characters in length",
      });
      return;
    }
    if (!giveAwayCreditAmount) {
      Swal.fire({
        icon: "warning",
        title: "Credit Amount required",
        text: "Please provide a valid credit amount greater than 0",
      });
      return;
    }

    setLoadingGiveAwayCreditModal(true);
    // Api call to add credits to all users
    const data = await giveAwayFreeCreditsToUser(
      giveAwayCreditAmount,
      giveAwayRemark
    );
    // console.log("Give away success: ", data);
    if (data.status === true) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: data.message,
      });
      setShowGiveAwayCreditModal(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: data.message,
      });
    }

    setLoadingGiveAwayCreditModal(false);
  };

  const creditBalancePopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">User credit balance</Popover.Header>
      <Popover.Body>
        User credit balance is assigned to provider or parent so, they can
        decode the code if they opt-in for decoding using messaging services.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Modal
        show={showCreditPaymentModal}
        onHide={() => setShowCreditPaymentModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            {createPayIntentLoading ? (
              <Spinner animation="border" />
            ) : (
              clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    user_id={userID}
                    productPrice={creditPrice && creditPrice}
                    pageRoute="/credits"
                    payment_type="add_credits"
                  />
                </Elements>
              )
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showGiveAwayCreditModal}
        onHide={() => setShowGiveAwayCreditModal(false)}
      >
        <Modal.Header closeButton>
          <h6>Give Away Free Credits to all users:</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormGroup className="mb-3">
              <Form.Label>Credit Amount:</Form.Label>
              <Form.Control
                type="number"
                min={1}
                value={giveAwayCreditAmount}
                onChange={(e) => setGiveAwayCreditAmount(e.target.value)}
                placeholder="Please enter give away credit amount"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Form.Label>Give Away Remark:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={giveAwayRemark}
                onChange={(e) => setGiveAwayRemark(e.target.value)}
                placeholder="Please enter give away remark"
              />
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleGiveAwayCredits}>Give Away</Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShowGiveAwayCreditModal(false);
            }}
            size="sm"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="px-4 mb-4 justify-content-center">
        <Row className="p-2">
          <Col className="pt-3 d-flex">
            <h3>Credit Balance</h3>
          </Col>
        </Row>

        {userData && userData.user_access === "admin" && (
          <Row className="pt-3">
            <Col className="d-flex justify-content-end">
              <Button onClick={handleShowGiveAwayFreeCreditsModal}>
                Give away Free Credits
              </Button>
            </Col>
          </Row>
        )}

        {userData && userData.user_access === "admin" && (
          <Row className="pt-3">
            <Col>
              <div>
                <div>Search Parent/Provider:</div>
                <SearchBarComponent
                  searchRoute="admin/filterParentsAndProviders"
                  triggerCloseOnSelection={true}
                  returnAction={(selected) => setCurrentUserID(selected._id)}
                  value_key="_id"
                />
              </div>
            </Col>
          </Row>
        )}

        <Row className="pt-3">
          <Col xs={12} sm={12} md={12} lg={6}>
            {userData && userData.user_access === "admin" && creditData && (userID !== creditData._id) && (
              <div className="mb-2">Selected User: {creditData.name}</div>
            )}
            {isLoading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : error ? (
              <div className="text-center">Error: {error.message}</div>
            ) : creditData ? (
              <div className="border rounded px-3 py-4">
                {userData &&
                userData.user_access === "admin" &&
                userData.id === currentUserID ? (
                  <div>No User Selected</div>
                ) : (
                  <>
                    <div className="w-100 d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <span className="credit_user_name">Available</span>
                        <div className="credit_user_balance d-flex flex-column">
                          <span className="credit_amount">
                            {creditData.credit_data[0]
                              ? creditData.credit_data[0].credit_balance
                              : "0"}
                          </span>
                          <span>credits</span>
                        </div>
                      </div>
                      <div>
                        <div className="ms-5 d-flex">
                          {showUpdateCreditBalance ? (
                            <FaTimesCircle
                              className="pointer-cursor text-danger"
                              style={{ fontSize: "28px" }}
                              title="Close add credit div"
                              onClick={() => setShowUpdateCreditBalance(false)}
                            />
                          ) : (
                            <FaPlusCircle
                              className="pointer-cursor text-primary"
                              style={{ fontSize: "28px" }}
                              title="Open add credit div"
                              onClick={() => setShowUpdateCreditBalance(true)}
                            />
                          )}
                          <OverlayTrigger
                            trigger="click"
                            placement="left"
                            overlay={creditBalancePopover}
                          >
                            <Button
                              variant="info"
                              style={{
                                height: "30px",
                                width: "30px",
                                outline: "0",
                              }}
                              className="ms-2 d-flex justify-content-center align-items-center text-dark rounded-circle credit_info_btn"
                              title="What is Credit Balance?"
                            >
                              <FaInfo size="sm" />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>

                    {showUpdateCreditBalance && (
                      <>
                        <motion.div
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                        >
                          <Row className="p-0 m-0 mt-3">
                            <Col className="p-0 m-0">
                              {userData && userData.user_access === "admin" && (
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    value={userCreditBalance}
                                    onChange={(e) =>
                                      setUserCreditBalance(e.target.value)
                                    }
                                    placeholder="Input credit balance"
                                  />
                                  <InputGroup.Text className="m-0 p-0">
                                    <Button
                                      onClick={(e) =>
                                        handleAddCreditToUser(e, currentUserID)
                                      }
                                      disabled={updatingUserCreditBalance}
                                    >
                                      {updatingUserCreditBalance ? (
                                        <Spinner size="sm" animation="border" />
                                      ) : (
                                        "Add Credit"
                                      )}
                                    </Button>
                                  </InputGroup.Text>
                                </InputGroup>
                              )}

                              {userData &&
                                (userData.user_access === "parent" ||
                                  userData.user_access === "provider") && (
                                  <>
                                    <SelectCardList
                                      cardHeight={140}
                                      cardWidth={140}
                                      returnSelectedItem={(item) =>
                                        setUserCreditBalance(item.value)
                                      }
                                      list={[
                                        {
                                          heading: "100 Credits",
                                          value: 100,
                                          text: "add 100 credits",
                                          icon: creditImage,
                                        },
                                        {
                                          heading: "250 Credits",
                                          value: 250,
                                          text: "add 500 credits",
                                          icon: creditImage,
                                        },
                                        {
                                          heading: "500 Credits",
                                          value: 500,
                                          text: "add 1000 credits",
                                          icon: creditImage,
                                        },
                                      ]}
                                    />
                                    <Button
                                      className="my-2"
                                      onClick={(e) =>
                                        handleAddCreditToUser(e, currentUserID)
                                      }
                                      disabled={updatingUserCreditBalance}
                                    >
                                      {createPayIntentLoading ? (
                                        <Spinner size="sm" animation="border" />
                                      ) : (
                                        "Add Credits"
                                      )}
                                    </Button>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </motion.div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div>No Credit Data exists</div>
            )}
          </Col>
        </Row>

        <Row className="pt-3">
          <Col>
            {isLoadingCreditTrans ? (
              <Spinner />
            ) : creditTransError ? (
              "Transaction Data could not be loaded"
            ) : currentUserTransData ? (
              <>
                {!(
                  userData &&
                  userData.user_access === "admin" &&
                  userData.id === currentUserID &&
                  userData.id === currentUserTransData._id
                ) && (
                  <>
                    {currentUserTransData.trans_data &&
                    currentUserTransData.trans_data.length === 0 ? (
                      <tr>
                        <td colSpan={5}>Transaction data doesn't exist.</td>
                      </tr>
                    ) : (
                      currentUserTransData.trans_data &&
                      currentUserTransData.trans_data.map((trans, idx) => (
                        <Row
                          key={idx}
                          className="m-0 my-2 p-2 border rounded trans_area"
                        >
                          <Col className="d-flex">
                            <div className="me-2 d-flex justify-content-center align-items-center">
                              <Image
                                className="trans_image"
                                src={
                                  trans.user_id !== trans.updated_by &&
                                  trans.type === "add_balance"
                                    ? transGiftImage
                                    : (trans.user_id === trans.updated_by && trans.type === "add_balance") ? transCreditImage : transImage
                                }
                              />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                              <div className="trans_remark">{trans.remark}</div>
                              <div className="trans_date">
                                {moment(trans.createdAt).format(
                                  "Do MMM, YYYY. hh:mm A"
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col className="d-flex justify-content-end align-items-center">
                            <div
                              className={`${
                                trans.type === "add_balance"
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                            >
                              {trans.type === "add_balance" ? "+" : "-"} &#x24;
                              {trans.balance}
                            </div>
                            <div className="ms-1 trans_show_more text-primary">
                              <span
                                className="pointer-cursor"
                                onClick={() => console.log(trans)}
                              >
                                &#62;
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ))
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </main>
    </>
  );
};

export default CreditBalance;
