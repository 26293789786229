/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaListOl, FaSort } from "react-icons/fa";
import { RiExpandUpDownFill } from "react-icons/ri";

import "./DataTable.module.css";
import useFetchDatatableInfo from "../../hooks/datatable_hooks/useFetchDatatableInfo";
import {
  sortArrayOfObjectsByDate,
  sortArrayOfObjectsByNumber,
  sortArrayOfObjectsByText,
} from "../../utils/utilFunctions";

const Datatable = ({
  fetch_url = "",
  fetch_count = 0,
  table_caption,
  table_values,
  loading = false,
  show_search_bar = true,
  column_heading,
  pageLimit = 0,
  row_span_column = "",
  table_classes = "",
}) => {
  const [pageLimitValue, setPageLimitValue] = useState(pageLimit);

  const [tableValues, setTableValues] = useState([]);
  const [columnHeadingValues, setColumnHeadingValues] =
    useState(column_heading);

  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([[]]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDataArray, setSortDataArray] = useState([]);
  const [currentDataCount, setCurrentDataCount] = useState(0);
  const [fetchTableDataCount, setFetchTableDataCount] = useState(0);
    
  const [reorderedColums, setReorderedColums] = useState([]);
  const [showReOrderColumnsModal, setShowReOrderColumnsModal] = useState(false);
  const [reorderingColumnsLoading, setReorderingColumnsLoading] = useState(false);

  const {
    page_data,
    page_count,
    page_current_series,
    page_count_series,
    total_data_count,
    data_loading,
  } = useFetchDatatableInfo({
    fetch_url,
    fetch_count,
    data: tableValues,
    column_heading,
    page_limit: pageLimitValue,
    page_no: pageNo,
    search_term: searchTerm,
    sort_data: sortDataArray,
    fetch_data_count: fetchTableDataCount,
  });

  useEffect(() => {
    if (fetch_url === "") {
      setTableValues(table_values);
    }
  }, [table_values]);

  useEffect(() => {
    setColumnHeadingValues(column_heading);
  }, [column_heading]);

  useEffect(() => {
    setPageLimitValue(pageLimit);
  }, []);

  useEffect(() => {
    if (page_data.length !== 0) {
      if (row_span_column === "") {
        setPageData([...page_data]);
      } else {
        // const groupedData = groupRowSpanData(page_data,"user_initial");
        const data = fetch_url === "" ? page_data[pageNo] : page_data[0];
        let groupedData = [];
        if (row_span_column === "") {
          groupedData = data;
        } else {
          groupedData = groupRowSpanData(data,row_span_column);
        }
        if (fetch_url === "") {
          page_data[0] = groupedData;
        } else {
          page_data[pageNo] = groupedData;
        }
        setPageData([...page_data]);
      }
    }
  }, [page_data]);

  useEffect(() => {
    setPageNo(1);
  }, [fetchTableDataCount]);

  useEffect(() => {
    setCurrentDataCount((prevValue) => {
      if (pageNo === 1 && pageData.length === 0) {
        return 0;
      } else if (fetch_url === "") {
        return pageLimitValue * (pageNo - 1) + pageData[pageNo - 1].length;
      } else {
        return pageLimitValue * (pageNo - 1) + pageData[0].length;
      }
    });
  }, [pageNo, pageData]);

  const groupRowSpanData = (data, col_name) => {
    let group_data = data;
    let prevUserInitial = null;
    
    group_data.forEach((item, idx) => {
      
      let currentUserInitial = item[col_name];
      let currentRowSpan = 1;
      let currentFirstOccurence = idx;
      
      for (let i = idx + 1; i < group_data.length; i++) {
        if (group_data[i][col_name] === currentUserInitial) {
          currentRowSpan++;
        } else {
          break;
        }
      }
      
      if (currentUserInitial !== prevUserInitial) {
        group_data[currentFirstOccurence].row_span_count = currentRowSpan; 
      }
      prevUserInitial = item[col_name];
    });

    return group_data;
  }

  const handleSortColumn = (colName, data_type) => {
    try {
      if (fetch_url === "") {
        sortStaticPassedData(colName, data_type);
      } else {
        // Need to update sort array according to dynamic data
        // [{"col":"user_initial","value":"desc"},{"col":"createdAt","value":"desc"}]
        setSortDataArray((prevArray) => {
          const updatedArray = [...prevArray];
          const currentIndex = updatedArray.findIndex(
            (item) => item.col === colName
          );

          if (currentIndex !== -1) {
            const [removedItem] = updatedArray.splice(currentIndex, 1);
            removedItem.value = removedItem.value === "desc" ? "asc" : "desc";
            // updatedArray.push(removedItem);
            updatedArray.unshift(removedItem);
          } else {
            // updatedArray.push({ col: colName, value: "asc" });
            updatedArray.unshift({ col: colName, value: "asc" });
          }

          return updatedArray;
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sortStaticPassedData = (colName, data_type) => {
    let col_data_type = data_type ? data_type : "text";
    let data = tableValues;
    // console.log("initial data: ", data)
    let sortedData = [];
    const colData = columnHeadingValues.find(
      (item) => colName === item.heading
    );
    // console.log("columnHeadingValues: ", colData)
    let sort_order = "asc";
    if (colData.sort_order === "asc") {
      sort_order = "desc";
    } else {
      sort_order = "asc";
    }

    setColumnHeadingValues((prev) => {
      return prev.map((item) => {
        if (item.heading === colName) {
          return {
            ...item,
            sort_order,
          };
        }
        return item;
      });
    });

    if (col_data_type === "text") {
      sortedData = sortArrayOfObjectsByText(data, colName, sort_order);
    } else if (col_data_type === "number") {
      sortedData = sortArrayOfObjectsByNumber(data, colName, sort_order);
    } else if (
      col_data_type === "date-time" ||
      col_data_type === "date" ||
      col_data_type === "time"
    ) {
      sortedData = sortArrayOfObjectsByDate(data, colName, sort_order);
    }

    setTableValues([...sortedData]);
  };

  // COde to render each cell of the Table STARTS here
  const renderPageData = (data, idx) => {
    return (
      <tr key={idx}>
        {columnHeadingValues &&
          columnHeadingValues.map((head, index) => {
           if (row_span_column !== "" && !data['row_span_count'] && head.heading === row_span_column) {
             return null;
            } else {
              return <TableCellData key={index} index={index} idx={idx} head={head} data={data} />
            }
          })}
      </tr>
    );
  };

  const TableCellData = ({ index, idx, head, data }) => {
    return (
      <td className={`${head.classes ? head.classes : ""}`} rowSpan={(row_span_column !== "" && data['row_span_count'] && head.heading === row_span_column)?data['row_span_count']:1}>
        {head.render
          ? head.render(
              data[head.heading],
              data,
              pageNo === 1 ? idx : pageLimitValue * (pageNo - 1) + idx,
              index
            )
          : head.data_type === "date-time"
          ? data[head.heading] &&
            moment(data[head.heading]).format("Do MMM, YYYY hh:mm A")
          : data[head.heading]}
      </td>
    )
  }
  // COde to render each cell of the Table ENDS here
  
  // Functions to reorder column STARTS here
  const handleShowReOrderColumnsModal = () => {
    setShowReOrderColumnsModal(true);
    setReorderedColums([...columnHeadingValues]);
  }

  const handleReorderColumnDragStart = (e, current_index) => {
    // console.log("drag started: ", e, current_index);
    e.dataTransfer.setData('widgetType', current_index);
  }

  const handleReorderColumnDrop = (e, index) => {
    const indexToMove = e.dataTransfer.getData("widgetType");
    const currentPositionData = reorderedColums.find((item, idx) => (idx === index));
    if (indexToMove !== -1 && currentPositionData && currentPositionData.reorderable === true) {
      setReorderedColums((prevItems) => {
        let data = prevItems;
        const itemToMove = data.splice(indexToMove, 1)[0];  // delete item from where it already exists
        data.splice(index, 0, itemToMove);  // insert the item in new index
        return [...data];
      })
    }
  }

  const handleReorderColumnDragOver = (e) => {
    e.preventDefault();
    // console.log("dragging");
  }

  const handleReorderColumnValues = (e) => {
    e.preventDefault();
    setReorderingColumnsLoading(true);
    setColumnHeadingValues([...reorderedColums]);
    setShowReOrderColumnsModal(false);
    setReorderingColumnsLoading(false);
  }
  // Functions to reorder column ENDS here

  return (
    <div>
      <Modal
        size="md"
        show={showReOrderColumnsModal}
        onHide={() => {
          setShowReOrderColumnsModal(false);
        }}
      >
        <Modal.Header closeButton><h5>Re-Order Table Columns</h5></Modal.Header>
        <Modal.Body>

          <div className="w-100">
            {reorderedColums.map((col, idx) => (
              <div
                key={idx}
                className={`border rounded p-2 my-2 w-100 ${!col.reorderable?"bg-secondary bg-opacity-25":""}`}
                style={{ cursor: col.reorderable?"move":"not-allowed" }}
                draggable={col.reorderable}
                onDragStart={(e) => handleReorderColumnDragStart(e, idx)}
                onDrop={(e) => handleReorderColumnDrop(e, idx)}
                onDragOver={handleReorderColumnDragOver}
              >
                <RiExpandUpDownFill className="me-1" />{col.title_text}
              </div>
            ))}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleReorderColumnValues}>{reorderingColumnsLoading?<Spinner animation="border" size="sm" />:"Re-Order"}</Button>
          <Button variant="secondary" onClick={() => setShowReOrderColumnsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="p-0 m-0" style={{ fontSize: "14px" }}>
        <Col xs={4} sm={4} md={3} lg={2}>
          <FormGroup>
            <Form.Label>Page Limit:&nbsp;</Form.Label>
            <Form.Select
              value={pageLimitValue}
              style={{ fontSize: "14px" }}
              onChange={(e) => setPageLimitValue(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="0">All</option>
            </Form.Select>
          </FormGroup>
        </Col>
        <Col
          xs={8}
          sm={8}
          md={9}
          lg={10}
          className="d-flex justify-content-end"
        >
          <div className="d-flex jusify-content-center align-items-end me-2">
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Re-Order Table Columns
                </Tooltip>
              }
            >
              <Button variant="info" className="rounded-circle d-flex justify-content-center align-items-center m-0 p-0" onClick={handleShowReOrderColumnsModal} style={{ height: "35px", width: "35px" }}>
                <FaListOl />
              </Button>
            </OverlayTrigger>
          </div>
          {show_search_bar && (
            <FormGroup>
              <Form.Label>Enter Search Term:&nbsp;</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.which === 13)
                      setFetchTableDataCount((prevValue) => prevValue + 1);
                  }}
                  style={{ fontSize: "14px" }}
                  placeholder="Enter search term"
                />
                <InputGroup.Text className="m-0 p-0">
                  <Button
                    onClick={() =>
                      setFetchTableDataCount((prevValue) => prevValue + 1)
                    }
                    style={{ fontSize: "14px" }}
                  >
                    Search
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </FormGroup>
          )}
        </Col>
      </Row>

      <Table className={`${table_classes !== ""?table_classes:""}`} bordered hover responsive>
        {table_caption && (
          <caption style={{ captionSide: "top" }}>{table_caption}</caption>
        )}
        <thead>
          <tr>
            {columnHeadingValues &&
              columnHeadingValues.map((head, idx) => (
                <th
                  key={idx}
                  onClick={() => {
                    if (head.sortable) {
                      handleSortColumn(head.heading, head.data_type);
                    }
                  }}
                  className={`${head.sortable ? "pointer-cursor" : ""} ${
                    head.title_classes ? `${head.title_classes}` : ""
                  }`}
                >
                  <span>
                    {head.sortable && (
                      <span className="table_sort_icon">
                        <FaSort className="text-primary" />
                      </span>
                    )}
                    {head.title_text}
                  </span>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {(loading || data_loading) && (
            <tr>
              <td colSpan={columnHeadingValues && columnHeadingValues.length}>
                <Spinner animation="border" />
              </td>
            </tr>
          )}
          {fetch_url !== "" && !loading && !data_loading && pageData && pageData[0].length === 0 && (
            <tr>
              <td colSpan={columnHeadingValues.length}>No data exists.</td>
            </tr>
          )}
          {fetch_url === "" && !loading && !data_loading && pageData && pageData[pageNo - 1].length === 0 && (
            <tr>
              <td colSpan={columnHeadingValues.length}>No data exists.</td>
            </tr>
          )}
          {pageData &&
            !loading &&
            !data_loading &&
            pageData.length > 0 &&
            fetch_url === "" && (
              <>
                {pageData[pageNo - 1].map((data, idx) => {
                  return renderPageData(data, idx);
                })}
              </>
            )}
          {pageData &&
            !loading &&
            !data_loading &&
            pageData.length > 0 &&
            fetch_url !== "" && (
              <>
                {pageData[0].map((data, idx) => {
                  return renderPageData(data, idx);
                })}
              </>
            )}
        </tbody>
      </Table>

      <Row className="m-0 my-2 p-0" style={{ fontSize: "14px" }}>
        <Col>
          <div>
            PAGE: {pageNo} of {page_count}
          </div>
          {typeof(total_data_count) === 'number' && (
            <div>
              {currentDataCount} of {total_data_count} entries
            </div>
          )}
        </Col>
        <Col>
          <div className="d-flex justify-content-end align-items-end">
            <Button
              className="me-1"
              variant="secondary"
              onClick={() => {
                if (pageNo !== 1) setPageNo(1);
              }}
              disabled={pageNo === 1 && true}
              style={{ fontSize: "14px" }}
            >
              First
            </Button>
            <Button
              className="mx-1"
              variant="secondary"
              onClick={() => {
                if (pageNo !== 1) setPageNo(pageNo - 1);
              }}
              disabled={pageNo === 1 && true}
              style={{ fontSize: "14px" }}
            >
              Prev
            </Button>
            {page_count_series && page_count_series.length === 1 && (
              <Button
                className="table-pagination mx-1"
                variant="light"
                style={{ fontSize: "14px" }}
              >
                1
              </Button>
            )}
            {page_count_series &&
              page_count_series.length <= 4 &&
              page_count_series.length > 1 &&
              page_count_series.map((number) => (
                <Button
                  className="table-pagination mx-1"
                  variant={`${number === pageNo ? "light" : "secondary"}`}
                  key={number}
                  onClick={() => setPageNo(number)}
                  style={{ fontSize: "14px" }}
                >
                  {number}
                </Button>
              ))}
            {page_count_series &&
              page_count_series.length > 4 &&
              page_current_series.map((number) => (
                <Button
                  className="table-pagination mx-1"
                  variant={`${number === pageNo ? "light" : "secondary"}`}
                  key={number}
                  onClick={() => setPageNo(number)}
                  style={{ fontSize: "14px" }}
                >
                  {number}
                </Button>
              ))}
            <Button
              className="mx-1"
              variant="secondary"
              onClick={() => {
                if (pageNo < page_count) setPageNo(pageNo + 1);
              }}
              disabled={pageNo === page_count && true}
              style={{ fontSize: "14px" }}
            >
              Next
            </Button>
            <Button
              className="ms-1"
              variant="secondary"
              onClick={() => {
                if (pageNo !== page_count) setPageNo(page_count);
              }}
              disabled={pageNo === page_count && true}
              style={{ fontSize: "14px" }}
            >
              Last
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Datatable;
