import React from "react";
import { Image } from "react-bootstrap";
import displayProfileImage from "../../assets/img/icons/user.png";

// import "./profile-avatar.styles.css";

const ProfileAvatar = ({ imageUrl, diameter = 180, passedClasses, edges = "roundedCircle" }) => {

  return (
    <>
      <Image
        className={`${passedClasses && passedClasses} ${edges === "roundedCircle" ? "rounded-circle" : "rounded"}`}
        src={imageUrl ? imageUrl : displayProfileImage }
        style={{
          width: `${diameter}px`,
          height: `${diameter}px`,
          objectFit: "cover",
        }}
      />
    </>
  );
};

export default ProfileAvatar;
