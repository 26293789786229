import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, Col, Form, FormGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { FaEdit, FaEye, FaPlusCircle, FaRegCheckCircle, FaSave } from 'react-icons/fa';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { BASE_URL } from '../../config/config';
import Datatable from '../../components/DataTable/Datatable';

const ManagePopups = () => {
  const [popupsList, setPopupsList] = useState([]);
  const [popupsListLoading, setPopupsListLoading] = useState(false);
  
  const [popupDataMode, setPopupDataMode] = useState('add');  // add, view, edit
  const [showAddEditViewPopupModal, setShowAddEditViewPopupModal] = useState(false);
  const [currentPopupData, setCurrentPopupData] = useState({ _id: "", popup_name: "", popup_slug: "", popup_description: "" });

  const [savingPopupData, setSavingPopupData] = useState(false);
  const [invalidPopupName, setInvalidPopupName] = useState(false);
  const [invalidPopupNameMessage, setInvalidPopupNameMessage] = useState("");
  const [invalidPopupSlug, setInvalidPopupSlug] = useState(false);
  const [invalidPopupSlugMessage, setInvalidPopupSlugMessage] = useState("");

  useEffect(() => {
    fetchPopupList()
  },[]);

  const fetchPopupList = async() => {
    try {
      if (popupsListLoading) return;
      setPopupsListLoading(true);
      const response = await axios.get(`${BASE_URL}/setting/getAllPopupsSetting`,
        {
          withCredentials: true
        }
      );
      if (response.status === 200) {
        // console.log("response: ", response);
        setPopupsList(response.data.popup_list);
        setPopupsListLoading(false);
      }

    } catch(e) {
      setPopupsListLoading(false);
      if (e.response) {
        console.log(e.response);
      } else {
        console.log(e);
      }
    }
  }

  const resetInvalidErrorMessages = () => {
    setInvalidPopupName(false);
    setInvalidPopupNameMessage("");
    setInvalidPopupSlug(false);
    setInvalidPopupSlugMessage("");
  }

  const handleClosePopupModal = () => {
    setShowAddEditViewPopupModal(false);
    setCurrentPopupData({ _id: "", popup_name: "", popup_slug: "", popup_description: "" });
    resetInvalidErrorMessages();
  }

  const handleCurrentPopupDataChange = (value, field) => {
    setCurrentPopupData({
      ...currentPopupData,
      [field]: value,
    });
  }

  const handleOpenAddPopupModal = () => {
    setPopupDataMode("add");
    setCurrentPopupData({ _id: "", popup_name: "", popup_slug: "", popup_description: "" });
    setShowAddEditViewPopupModal(true);
  }
  
  const handleEditPopupData = (popup_data) => {
    if (!popup_data) return;
    setPopupDataMode("edit");
    setCurrentPopupData(popup_data);
    setShowAddEditViewPopupModal(true);
  }

  const handleViewPopupData = (popup_data) => {
    if (!popup_data) return;
    setPopupDataMode("view");
    setCurrentPopupData(popup_data);
    setShowAddEditViewPopupModal(true);
  }

  const validPopupData = () => {
    let status = true;

    if (currentPopupData.popup_name.length < 3 || currentPopupData.popup_name.length > 30) {
      setInvalidPopupName(true);
      setInvalidPopupNameMessage("Popup heading should be between 3 to 30 characters");
      status = false;
    } else {
      setInvalidPopupName(false);
      setInvalidPopupNameMessage("");
    }

    if (popupDataMode === "add" && (currentPopupData.popup_slug.length < 3 || currentPopupData.popup_slug.length > 80)) {
      setInvalidPopupSlug(true);
      setInvalidPopupSlugMessage("Popup Slug should be between 3 to 80 characters")
      status = false;
    } else if (!/^[a-zA-Z0-9-]+$/.test(currentPopupData.popup_slug)) {
      setInvalidPopupSlug(true);
      setInvalidPopupSlugMessage("Popup Slug is invalid. Only hyphens, alphabets, numbers are allowed, without spaces.")
      status = false;
    } else {
      setInvalidPopupSlug(false);
      setInvalidPopupSlugMessage("")
    }

    if (currentPopupData.popup_description.length === 0 || currentPopupData.popup_description.length < 20) {
      // Popup description should be atleast 20 characters
      Swal.fire({
        icon: 'error',
        title: 'Stop',
        text: 'Popup description must have atleast 20 characters.',
      });
      status = false;
    }

    return status;
  }
  
  const handleUpdateOrSavePopupData = async() => {
    try {
      if (savingPopupData) return;
      if (!validPopupData(currentPopupData)) return;
      setSavingPopupData(true);
      const response = await axios.post(`${BASE_URL}/setting/addOrUpdatePopupSetting`,
        {
          popup_id: currentPopupData._id,
          name: currentPopupData.popup_name,
          slug: currentPopupData.popup_slug,
          description: currentPopupData.popup_description,
        },
        {
          withCredentials: true
        }
      );
      if (response.status === 200) {
        setSavingPopupData(false);
        fetchPopupList();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
        handleClosePopupModal();
      }

    } catch(e) {
      setSavingPopupData(false);
      if (e.response) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Something went wrong",
        });
      }
    }
  }
  return (
    <>
      <Modal
        size="xl"
        show={showAddEditViewPopupModal}
        onHide={handleClosePopupModal}
      >
        <Modal.Header closeButton>
          <h5 className="p-0 m-0">{popupDataMode === "edit"?"Update Popup Data":popupDataMode === "add"?"Save Popup Data":"View Popup Data"}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <FormGroup className='mb-3'>
              <Form.Label>Popup Name:</Form.Label>
              <Form.Control type='text' isInvalid={invalidPopupName} placeholder='Enter Popup Name' value={currentPopupData.popup_name} onChange={(e) => handleCurrentPopupDataChange(e.target.value, 'popup_name')} disabled={popupDataMode === "view"} />
              {invalidPopupName && (
                <Form.Control.Feedback type="invalid">
                  {invalidPopupNameMessage}
                </Form.Control.Feedback>
              )}
            </FormGroup>
            <FormGroup className='mb-3'>
              <Form.Label>Popup Slug:</Form.Label>
              <Form.Control type='text' isInvalid={invalidPopupSlug} placeholder='Enter Popup Slug' value={currentPopupData.popup_slug} onChange={(e) => handleCurrentPopupDataChange(e.target.value, 'popup_slug')} disabled={popupDataMode === "add"?false:true} />
              {invalidPopupSlug && (
                <Form.Control.Feedback type="invalid">
                  {invalidPopupSlugMessage}
                </Form.Control.Feedback>
              )}
            </FormGroup>
            <FormGroup className="mb-2">
              <Form.Label>Popup Content:</Form.Label>
              <CKEditor
                editor={Editor}
                disabled={popupDataMode === "view"}
                config={ {
                  isReadOnly: popupDataMode === "view",
                  height: "100px",
                  // toolbar: [
                  //   'undo', 'redo',
                  //   '|', 'heading',
                  //   '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                  //   '|', 'alignment',
                  //   '|', 'blockQuote', 'codeBlock',
                  //   '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                  // ],
                } }
                data={currentPopupData.popup_description}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    // console.log( { event, editor, data } );
                    handleCurrentPopupDataChange(data, 'popup_description')
                } }
                onBlur={ ( event, editor ) => {
                  // console.log( 'Blur.', editor );
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  handleCurrentPopupDataChange(data, 'popup_description')
                } }
                onFocus={ ( event, editor ) => {
                  // console.log( 'Focus.', editor );
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  handleCurrentPopupDataChange(data, 'popup_description')
                } }
              />
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {popupDataMode !== "view" && (
            <Button
              onClick={handleUpdateOrSavePopupData}
              size="sm"
            >
              {savingPopupData ? (
                <Spinner size='sm' animation='border' />
              ) : popupDataMode === "edit"? (
                <><FaRegCheckCircle /> Update</>
              ): <><FaSave /> Save</>}
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={handleClosePopupModal}
            size="sm"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="px-4 mb-4 justify-content-center">
          <Row className="p-2">
            <Col className="pt-3 d-flex">
              <h3>Manage Popups</h3>
            </Col>
          </Row>
          <Row>
            <Col className="pt-3">
              <div className='mb-2 d-flex justify-content-end'>
                <Button onClick={handleOpenAddPopupModal}><FaPlusCircle /> Add Popup</Button>
              </div>
              <Datatable
                loading={popupsListLoading}
                pageLimit={10}
                table_classes="mt-3"
                show_search_bar={false}
                table_values={popupsList}
                column_heading={[
                  {
                    title_text: "Sl.No",
                    heading: "_id",
                    reorderable: true,
                    render: (data, row, rowIndex) => {
                      return ++rowIndex;
                    },
                  },
                  {
                    title_text: "Heading",
                    heading: "popup_name",
                    searchable: true,
                    reorderable: true,
                  },
                  {
                    title_text: "Slug Value",
                    heading: "popup_slug",
                    searchable: true,
                    reorderable: true,
                  },
                  {
                    title_text: "Actions",
                    heading: "_id",
                    reorderable: false,
                    render: (data, row) => {
                      return (
                        <>
                          <FaEye className='me-1 pointer-cursor' title='View Popup Details' onClick={() => handleViewPopupData(row)} />
                          <FaEdit
                            className='text-primary me-1 pointer-cursor'
                            title='Edit Popup Details'
                            onClick={() => handleEditPopupData(row)}
                          />
                        </>
                      );
                    }
                  },
                ]}
              />
            </Col>
          </Row>
      </main>
    </>
  )
}

export default ManagePopups