import React from "react";
import { Link  } from "react-router-dom";
import { Button, Nav } from "react-bootstrap";
import { FaUsers, FaSignInAlt, FaStream, FaHistory, FaUser, FaHeadphonesAlt, FaListAlt, FaUserShield, FaDollarSign, FaRegChartBar } from "react-icons/fa";
import { BsBoxArrowUpRight } from 'react-icons/bs';

const SideBarOptions = ({ userName, userType, onClick }) => {
  return (
    <>
    
      <Nav.Link
        className='d-flex align-items-center bg-light p-2 w-100 rounded mb-2'
        style={{ textDecoration: "none", color: "#000" }}
      >
        <FaUser
          title='User Info'
          className='me-2'
          color='black'
          size='18px'
        />
        Hi {userName.split(" ")[0]}
      </Nav.Link>
      <Link to='/dashboard' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className='d-flex align-items-center bg-light p-2 d-flex justify-content-start w-100 rounded mb-2'
          style={{ textDecoration: "none", color: "#000" }}
          title='User Dashboard'
        >
          <FaStream
            className='me-2'
            color='black'
            size='18px'
          />
          Dashboard
        </Nav.Link>
      </Link>
      <Link to='/manage-users' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType === "admin" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='Manage Non-Admin Users'
        >
          <FaUsers
            className='me-2'
            color='black'
            size='18px'
          />
          Manage Users
        </Nav.Link>
      </Link>
      <Link to='/manage-admins' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType === "admin" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='Manage Admins Users'
        >
          <FaUserShield
            className='me-2'
            color='black'
            size='18px'
          />
          Manage Admins
        </Nav.Link>
      </Link>
      <Link to='/access-logs' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType === "admin" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='User Access Logs'
        >
          <FaHistory
            className='me-2'
            color='black'
            size='18px'
          />
          Access Logs
        </Nav.Link>
      </Link>
      {/* <Link to='/credits' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType !== "user" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='User Credit Details'
        >
          <FaDollarSign
            className='me-2'
            color='black'
            size='18px'
          />
          Credits
        </Nav.Link>
      </Link> */}
      <Link to='/statistics' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType === "admin" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='User Activity Statistics'
        >
          <FaRegChartBar
            className='me-2'
            color='black'
            size='18px'
          />
          Statistics
        </Nav.Link>
      </Link>
      <Link to='/manage-terms' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType === "admin" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='Manage Terms & Conditions'
        >
          <FaListAlt
            className='me-2'
            color='black'
            size='18px'
          />
          Manage Terms
        </Nav.Link>
      </Link>
      <Link to='/manage-popups' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className={`${
            userType === "admin" ? "d-flex" : "d-none"
          } align-items-center bg-light p-2 w-100 rounded mb-2`}
          style={{ textDecoration: "none", color: "#000" }}
          title='Manage Popups'
        >
          <BsBoxArrowUpRight
            className='me-2'
            color='black'
            size='18px'
          />
          Manage Popups
        </Nav.Link>
      </Link>
      <Link to='/support' className="w-100" style={{ textDecoration: "none", color: "#000" }}>
        <Nav.Link
          as={Button}
          className="d-flex align-items-center bg-light p-2 w-100 rounded mb-2"
          style={{ textDecoration: "none", color: "#000" }}
          title='Request Support'
        >
          <FaHeadphonesAlt
            className='me-2'
            color='black'
            size='18px'
          />
          Support
        </Nav.Link>
      </Link>
      <Nav.Link
        as={Button}
        onClick={onClick}
        className='bg-danger p-2 w-100 rounded mb-2'
        style={{ textDecoration: "none", color: "#000" }}
        title='Logout From Blindweight'
      >
        <FaSignInAlt color='black' size='18px' />
        &nbsp; Logout
      </Nav.Link>

    </>
  );
};

export default SideBarOptions;
