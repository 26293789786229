import React, { Suspense  } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Dashboard from './pages/Dashboard/Dashboard';
import ManageUsers from './pages/ManageUsers/ManageUsers';
import ManageAdmins from './pages/ManageAdmins/ManageAdmins';
import CreditBalance from './pages/CreditBalance/CreditBalance';
import UserStats from './pages/UserStats/UserStats';
import ManageTerms from './pages/ManageTerms/ManageTerms';
import ManagePopups from "./pages/ManagePopups/ManagePopups";
import AccessLogs from './pages/AccessLogs/AccessLogs';
import Support from './pages/Support/Support';

import EditUser from './pages/ManageUsers/EditUser/EditUser';
import EditAdmin from './pages/ManageAdmins/EditAdmin/EditAdmin';

import ProtectedRoutes from "./components/ProtectedRoutes";
import LoaderSpinner from "./components/LoaderSpinner/LoaderSpinner";

import "./App.css";

const SignUp = React.lazy(() => import('./pages/SignUp/SignUp'));
const LoginPage = React.lazy(() => import('./pages/LoginPage/LoginPage'));
const PageNotFound = React.lazy(() => import('./pages/PageNotFound/PageNotFound'));
const PasswordReset = React.lazy(() => import('./pages/PasswordReset/PasswordReset'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const PaymentSuccess = React.lazy(() => import('./pages/PaymentSuccess/PaymentSuccess'));

// Create a client
const queryClient = new QueryClient();

const App = () => {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<div className="mt-5"><LoaderSpinner /></div>}>
            <Routes>
                <Route path='/' element={ <Navigate to="/login" /> }/>
                <Route path='/login' element={<LoginPage/>} />
                <Route path='/sign-up' element={<SignUp/>} />
                <Route path='/sign-up/:user_id' element={<SignUp/>} />
                <Route path='/password-reset/:userId/:token' element={<PasswordReset/>} />
                
                <Route path='/payment-success/:user_id/:payment_type/:prev_page' element={<PaymentSuccess/>} />
                <Route path='blindweight_privacy_policy' element={<PrivacyPolicy/>} />

                <Route path='/' element={<ProtectedRoutes accessible_to={['admin','parent','provider','user']} />}>

                  <Route path='dashboard' element={<Dashboard/>} />
                  {/* <Route path='my-settings' element={<MySettings/>} /> */}
                  <Route path='support' element={<Support/>} />

                </Route>

                <Route path='/' element={<ProtectedRoutes accessible_to={['admin']} />}>

                  <Route path='manage-users' element={<ManageUsers/>} />
                  <Route path='manage-admins' element={<ManageAdmins/>} />
                  <Route path='edit-user/:user_id' element={<EditUser/>} />
                  <Route path='edit-admin/:user_id' element={<EditAdmin/>} />
                  <Route path='access-logs' element={<AccessLogs/>} />
                  <Route path='privacy_policy' element={<PrivacyPolicy/>} />
                  <Route path='statistics' element={<UserStats />} />
                  {/* <Route path='credits' element={<CreditBalance />} /> */}
                  <Route path='manage-terms' element={<ManageTerms />} />
                  <Route path='manage-popups' element={<ManagePopups />} />
                  
                </Route>
                
                <Route path='*' element={<Navigate to='not-found' />} />
                <Route path='/not-found' element={<PageNotFound />} />

              </Routes>
            </Suspense>
        </QueryClientProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
};

export default App;
