import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import { BASE_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LogUserActions } from "../../functionalities/loggedActions";
import SearchBarComponent from "../../components/SearchBarComponent/SearchBarComponent";
// import DataTable from "../../components/DataTable";

const AccessLogs = (props) => {
  const history = useNavigate();
  const [cookies] = useCookies(["blind_weight"]);

  const [accessUser, setAccessUser] = useState("");
  const [userLogs, setUserLogs] = useState([]);
  const [accessLogsLoaded, setAccessLogsLoaded] = useState(false);

  const token = cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
  const decoded = jwt_decode(token);

  const fetchAccessLogs = async (user_id) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/fetchRecentLogs`,
        {
          user_id,
        },
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );
      if (response.status === 200) {
        // console.log(response);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
        setUserLogs(response.data.loggedData);
        setAccessLogsLoaded(true);
        await LogUserActions(decoded.id, "Fetch User AccessLogs");
        return;
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Oops...!!!",
          text: error.response.data.message,
        });
      } else {
        console.log(error.message);
        Swal.fire({
          icon: "error",
          title: "Oops...!!!",
          text: "Something went wrong.",
        });
      }
      setAccessLogsLoaded(true);
      return;
    }
  };

  useEffect(() => {
    if (decoded) {
      if (decoded.user_access !== "admin") {
        history("/dashboard");
        return;
      }

      fetchAccessLogs(decoded.id);
      setAccessUser(decoded);
    }
  }, []);
  

  return (
    <main className='px-4 mb-4 justify-content-center'>
      <Row className='m-2 mt-4'>
        <Col md={2} lg={3} xl={4}></Col>
        <Col xs={12} sm={5} md={4} lg={4} xl={4} className='p-0 m-0 mb-2'>
          <SearchBarComponent
            divClasses="p-0 m-0"
            searchRoute="admin/searchUserList"
            searchPlaceholder="Search Users"
            triggerCloseOnSelection={true}
            // showTermAfterSelection={true}
            returnAction={(selected) => setAccessUser(selected)}
            value_key="name"
          />
        </Col>
        <Col xs={12} sm={7} md={6} lg={5} xl={4} className='p-0 m-0 mb-2 d-flex justify-content-end'>
          <Form.Group className='d-flex'>
            <Form.Label>Selected User: {accessUser?accessUser.name.split()[0]:"Not Selected"}</Form.Label>
            <div>
              <Button
                className='ms-2 '
                onClick={(e) => {
                  e.preventDefault();
                  setAccessLogsLoaded(false);
                  fetchAccessLogs(accessUser._id);
                }}
              >
                {!accessLogsLoaded ? (
                  <Spinner size='sm' animation='border' />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <DataTable
            columnHeads={[
              {
                title: "Sl.No.",
                dataField: "_id",
                formatter: (value, row, rowIndex) => {
                  return ++rowIndex;
                },
                headerStyles: "text-center",
                cellStyles: "text-center",
              },
              {
                title: "Log Message",
                dataField: "log_message",
                sortable: true,
                headerStyles: "text-center",
                cellStyles: "text-center",
              },
              {
                title: "Log Time",
                dataField: "createdAt",
                sortable: true,
                headerStyles: "text-center",
                cellStyles: "text-center",
                formatter: (value, row, rowIndex) => {
                  return moment(value).format("MM-DD-YYYY, hh:mm:ss a")
                }
              },
            ]}
            data={userLogs}
            sortData={(d) => setUserLogs([...d])}
            // rowsPerPage={dataTableRange}
          /> */}
          <Table bordered striped responsive>
            <thead className='text-center'>
              <tr>
                <th>Sl.No.</th>
                <th>Log Message</th>
                <th>Log Time</th>
              </tr>
            </thead>
            <tbody className='text-center'>
              {!accessLogsLoaded ? (
                <tr className='text-center m-3'>
                  <td colSpan={5}>
                    <Spinner animation='border' />
                  </td>
                </tr>
              ) : userLogs.length === 0 ? (
                <tr>
                  <td colSpan={3}>No Access Logs exists.</td>
                </tr>
              ) : (
                userLogs.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.log_message}</td>
                    <td>
                      {moment(item.createdAt).format("DD-MM-YYYY, hh:mm:ss a")}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </main>
  );
};

export default AccessLogs;
