import axios from "axios";
import { BASE_URL } from "../config/config";

export const LogUserActions = async (user_id, log_message, cookies) => {
  try {
    const response = await axios.post(`${BASE_URL}/user/logUserAction`, {
      user_id,
      log_message,
    });
    if (response.status === 200) {
      // console.log(response.data.message);
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data.message);
    } else {
      console.log(error);
    }
  }
};
