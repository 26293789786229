import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { BASE_URL } from "../../../config/config";
import { LogUserActions } from "../../../functionalities/loggedActions";
import MobileNoInput from "../../../components/MobileNoInput/MobileNoInput";

const EditUser = (props) => {
  let history = useNavigate();
  let { user_id } = useParams();
  const [cookies] = useCookies(["blind_weight"]);

  const [userID, setUserID] = useState(null);
  const [userDetailsLoaded, setUserDetailsLoaded] = useState(false);

  // States for form user details
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userAccessType, setUserAccessType] = useState("");
  const [providerName, setProviderName] = useState("");
  const [showProviderName, setShowProviderName] = useState(false);
  const [adminPassword, setAdminPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [userPass, setUserPass] = useState("");
  const [userConfirmPass, setUserConfirmPass] = useState("");
  const [updating, setUpdating] = useState(false);

  const token =
    cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
  const decoded = jwt_decode(token);

  useEffect(() => {
    if (decoded.user_access !== "admin") {
      history("/dashboard");
      return;
    } else if (!userDetailsLoaded) {
      setUserID(user_id);
      fetchUserDetails(user_id);
    }
  }, [userDetailsLoaded]);

  useEffect(()=>{
    if(userAccessType === "admin" || userAccessType === "superadmin"){
      setProviderName("");
      setShowProviderName(false);
    }else{
      setShowProviderName(true);
    }
  },[userAccessType]);

  const fetchUserDetails = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/user/getUserDetails/${id}`);
      if (response.status === 200) {
        // console.log(response.data.userDetails);
        await LogUserActions(decoded.id, "Edit User Page Access");
        setUserName(response.data.userDetails.name);
        setUserEmail(response.data.userDetails.email);
        setUserMobile(response.data.userDetails.mobile_no.replace(/\D/g, "").slice(-10));
        setUserAccessType(response.data.userDetails.user_access);
        setProviderName(response.data.userDetails.user_provider_data);
        setUserDetailsLoaded(true);
        return;
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
      setUserDetailsLoaded(true);
      return;
    }
  };

  // Validation of Update User details
  const validateUserDetails = () => {
    let status = true;
    if (userName.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Username Empty",
        text: "User cannot be empty.",
      });
      status = false;
    } else if (userName.length < 3) {
      Swal.fire({
        icon: "warning",
        title: "Username too short",
        text: "Username must be atleast 3 characters.",
      });
      status = false;
    } else if (userEmail.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "User email empty",
        text: "User email cannot be empty.",
      });
      status = false;
    } else if (userMobile.replace(/\D/g, "").length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Mobile No empty",
        text: "Mobile number cannot be empty.",
      });
      status = false;
    } else if (userMobile.replace(/\D/g, "").length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Mobile No Invalid",
        text: "Mobile number should be a 10 digits number.",
      });
      status = false;
    } else if (changePassword) {
      if (userPass.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Password empty",
          text: "Password cannot be empty.",
        });
        status = false;
      } else if (userPass.length < 6) {
        Swal.fire({
          icon: "warning",
          title: "Password too short",
          text: "Password should be atleast 6 characters.",
        });
        status = false;
      } else if (userPass !== userConfirmPass) {
        Swal.fire({
          icon: "warning",
          title: "Passwords mismatch",
          text: "Passwords do not match.",
        });
        status = false;
      }
    }
    return status;
  };
  // API call for update user details
  const handleSaveUserDetails = async (e) => {
    e.preventDefault();
    const validation = validateUserDetails();
    if (!validation) {
      return;
    }
    try {
      setUpdating(true);
      let data = {
        user_id: userID,
        name: userName,
        email: userEmail,
        mobile_no: userMobile.replace(/\D/g, ""),
        user_access: userAccessType,
        user_provider_data: providerName,
        your_password: adminPassword,
        change_password: changePassword,
      };
      console.log(data);
      if (changePassword) {
        data.password = userPass;
        data.confirm_password = userConfirmPass;
      }
      // API call for Update User details
      const response = await axios.post(
        `${BASE_URL}/admin/updateUser`,
        data,
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );
      if (response.status === 200) {
        console.log(response);
        await LogUserActions(decoded.id, "Update User Details");
        Swal.fire({
          icon: "success",
          title: "User Updated",
          text: response.data.message,
        });
        history("/manage-users");
        setUpdating(false);
        return;
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...!!!",
          text: "Something went wrong.",
        });
      }
      setUpdating(false);
      return;
    }
  };

  return (
    <main className='px-4 mb-4'>
      <Container className='p-2'>
        <Row>
          <Col>
            <h2 className='text-center'>
              Edit User {!userDetailsLoaded && <Spinner animation='border' />}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                <Form.Label>User Name:</Form.Label>
                <Form.Control
                  type='text'
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder='Enter User Name'
                />
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                <Form.Label>User Email:</Form.Label>
                <Form.Control
                  type='email'
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder='Enter User Email'
                />
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                <Form.Label>User Mobile No:</Form.Label>
                {/* <Form.Control
                  type='tel'
                  value={userMobile}
                  onChange={(e) => {
                    const { value } = e.target;
                    // Replace any non-numeric characters with an empty string
                    const sanitizedValue = value.replace(/[^0-9]/g, '');
                    setUserMobile(sanitizedValue);
                  }}
                  placeholder='Enter your 10 digit Mobile Number'
                /> */}
                <MobileNoInput value={userMobile} onChange={(e) => setUserMobile(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                <Form.Label>Select User Access Type:</Form.Label>
                <Form.Select
                  value={userAccessType}
                  onChange={(e) => setUserAccessType(e.target.value)}
                >
                  <option value=''>Select User type...</option>
                  {/* <option value='superadmin'>SuperAdmin</option> */}
                  <option value='admin'>Admin</option>
                  <option value='provider'>Clinician/Provider</option>
                  <option value='parent'>Parent</option>
                  <option value='user'>User</option>
                </Form.Select>
              </Form.Group>
              {showProviderName && (
                <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                  <Form.Label>Enter Provider Name:</Form.Label>
                  <Form.Control
                    type='text'
                    value={providerName}
                    onChange={(e) => setProviderName(e.target.value)}
                    placeholder='Enter Provider'
                  />
                </Form.Group>
              )}
              <Form.Group as={Col} md={12} xs={12} className='mb-3'>
                {/* <Form.Label className='d-flex'> */}
                <Form.Label>
                  Change User Password:
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='button-tooltip'>
                        Toggle to set new Password
                      </Tooltip>
                    }
                  >
                    <Form.Check
                      inline
                      type='switch'
                      checked={changePassword}
                      onChange={(e) => {
                        setUserPass("");
                        setUserConfirmPass("");
                        setChangePassword(!changePassword);
                      }}
                      className='mx-2'
                      title='Want to change Password?'
                    />
                  </OverlayTrigger>
                </Form.Label>
                {changePassword && (
                  <Row>
                    <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                      <Form.Label>Set New Password:</Form.Label>
                      <Form.Control
                        type='password'
                        value={userPass}
                        onChange={(e) => setUserPass(e.target.value)}
                        placeholder='Set new user Password'
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                      <Form.Label>Confirm Password:</Form.Label>
                      <Form.Control
                        type='password'
                        value={userConfirmPass}
                        onChange={(e) => setUserConfirmPass(e.target.value)}
                        placeholder='Confirm user Password'
                      />
                    </Form.Group>
                  </Row>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className='mb-3'>
                <Form.Label>Your Password:</Form.Label>
                <Form.Control
                  type='password'
                  value={adminPassword}
                  onChange={(e) => setAdminPassword(e.target.value)}
                  placeholder='Enter your Password'
                />
              </Form.Group>
            </Row>
            <Button onClick={handleSaveUserDetails}>
              {updating ? (
                <Spinner animation='border' size='sm' />
              ) : (
                "Save User Details"
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default EditUser;
