import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  InputGroup,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import OnscreenKeyboard from "../OnscreenKeyboard/OnscreenKeyboard";
import bwVersionDemo from "../../assets/img/demos/bw_device_version.gif";
import bwKeyCodePair from "../../assets/img/key_codes.png";

import "./weightbox.styles.css";
import { FaQuestion, FaQuestionCircle } from "react-icons/fa";

const WeightBox = ({
  bgColor,
  formStatus = false,
  placeholderMessage,
  onChange,
  version = "v1",
  userData,
  changeVersion,
  handleKeyPress,
  inputValue,
  inputIcon,
  inputIconText="Send",
  inputIconLoading=false,
  iconClick,
  showKeyboard = false,
  screenInput,
  bottomLabel = "Blind Weight",
}) => {
  const [showWhatsNew, setShowWhatsNew] = useState(false);
  const [showFontKeyRecognition, setShowFontKeyRecognition] = useState(false);
  const [deviceVersion, setDeviceVersion] = useState(version);

  const [inputDisabled, setInputDisabled] = useState(true);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    setDeviceVersion(version);
  }, [version]);

  // Create a function to update windowInnerWidth
  const updateWindowInnerWidth = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  // Add an event listener when the component mounts
  useEffect(() => {
    window.addEventListener('resize', updateWindowInnerWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowInnerWidth);
    };
  }, []); // Empty dependency array ensures this effect only runs on mount and unmount


  useEffect(() => {
    if (windowInnerWidth < 768) {
      setInputDisabled(false);
    }
  }, [windowInnerWidth]);

  return (
    <>
      <Modal
        size="md"
        show={showWhatsNew}
        onHide={() => setShowWhatsNew(false)}
      >
        <Modal.Header closeButton>
          <h5 className="p-0 m-0">What's New?</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <span className="mb-3">You can change version to decode the codes here.</span>
            <br />
            <Image src={bwVersionDemo} className="w-100 rounded" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowWhatsNew(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={showFontKeyRecognition}
        onHide={() => setShowFontKeyRecognition(false)}
      >
        <Modal.Header closeButton>
          <h5 className="p-0 m-0">Font Recogintion Keys</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <span className="mb-3">You can see below the keyboard input values for the device codes:</span>
            <br />
            <Image src={bwKeyCodePair} className="w-100 rounded" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFontKeyRecognition(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className={`weight-box h-100 w-100 ps-2 pe-2 m-0 bg-${bgColor}`}
        style={{ borderRadius: "20px 20px 0 0" }}
      >
        <Row className="h-100 w-100 position-relative">
          <Col className="d-flex flex-column pt-2 pb-5 mx-0 h-100 justify-content-between align-items-center position-relative" >
            {userData && userData.user_access !== "user" && !formStatus && (
              <>
                <motion.div
                  whileHover={{ scale: 1.07, fontSize: "16px" }}
                  className="bg-bw-blue text-warning rounded-circle p-2 d-flex justify-content-center align-items-center pointer-cursor"
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "8px",
                    width: "25px",
                    height: "25px",
                  }}
                  title="What's new?"
                  onClick={() => setShowWhatsNew(true)}
                >
                  <FaQuestion
                    className="pointer-cursor"
                  />
                </motion.div>
              </>
            )}
            {userData && userData.user_access !== "user" && !formStatus && (
              <Row className="w-100 m-0 mb-0 pb-1">
                <Col xs={12} sm={12} md={12} lg={12} className="mb-1 mx-2">
                  <div className="text-center text-light p-0 m-0 mb-1 w-100 px-3" style={{ fontSize: "12px" }}>
                    <div><em>For best experiece, Please use On-Screen keyboard</em></div>
                    <div>
                      <em>To see Font Key recognition, Click Here&nbsp;
                        <span style={{ fontSize: "16px" }}>
                          <FaQuestionCircle
                            className="pointer-cursor text-bw-blue"
                            onClick={() => setShowFontKeyRecognition(true)}
                          />
                        </span>
                      </em>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className="p-0 m-0 pe-2">
                  <motion.button
                    whileHover={{ scale: 1.04, }}
                    className={`${
                      deviceVersion === "v1" ? "bg-primary" : "bg-secondary"
                    } btn btn-secondary btn-sm w-100 text-center rounded pointer-cursor`}
                    onClick={() => changeVersion("v1")}
                  >
                    Version 1
                  </motion.button>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className="p-0 m-0 ps-2">
                  <motion.button
                    whileHover={{ scale: 1.04, }}
                    className={`${
                      deviceVersion === "v2" ? "bg-primary" : "bg-secondary"
                    } btn btn-secondary btn-sm w-100 text-center rounded pointer-cursor`}
                    onClick={() => changeVersion("v2")}
                  >
                    Version 2
                  </motion.button>
                </Col>
              </Row>
            )}

            <InputGroup className={`${formStatus === true?"mt-0 m-md-5 m-lg-5 pt-5":"mt-0"}`}>
              <Form.Control
                disabled={!formStatus?inputDisabled:true}
                className="w-50 p-1 text-center"
                value={inputValue && inputValue}
                style={{
                  fontSize: "28px",
                  fontFamily: "Digital-Counter-7",
                }}
                placeholder={placeholderMessage}
                onChange={(e) => onChange(e)}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    iconClick(e);
                  } else {
                    handleKeyPress(e);
                  }
                }}
              />
              {inputIcon && (
                <InputGroup.Text
                  onClick={iconClick}
                  className="m-0 p-0 px-2"
                  style={{ cursor: "pointer" }}
                >
                  <motion.div whileHover={{ scale: 1.09 }}>
                    {inputIconLoading?(
                      <Spinner animation="border" size="sm" />
                    ):(
                      <>
                        <div>{inputIcon}</div>
                        <div style={{ fontSize: "12px" }}>{inputIconText}</div>
                      </>
                    )}
                  </motion.div>
                </InputGroup.Text>
              )}
            </InputGroup>

            {showKeyboard && (
              <OnscreenKeyboard
                buttonVariant="light"
                onPressButton={(e) => screenInput(e)}
              />
            )}
          </Col>
          <div
            style={{ bottom: "0" }}
            className="position-absolute bg-dark text-white p-1 border border-light rounded border-top"
          >
            {bottomLabel}
          </div>
        </Row>
      </div>
    </>
  );
};

export default WeightBox;
