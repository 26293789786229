import React from 'react';
import InputMask from 'react-input-mask';
import { Form } from 'react-bootstrap';

const MobileNoInput = (props) => {
  return (
    <InputMask 
      mask='(999) 999-9999' 
      value={props.value} 
      onChange={props.onChange}>
        {() => (
            <Form.Control
                type="text"
                isInvalid={props.isInvalid}
                placeholder="Enter 10 digit mobile number"
            />
        )}
    </InputMask>
  )
}

export default MobileNoInput;