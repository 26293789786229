import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Offcanvas,
  Card,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

import { BASE_URL } from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FaEdit, FaInfoCircle, FaSearch, FaTimes, FaTrashAlt } from "react-icons/fa";
import { LogUserActions } from "../../functionalities/loggedActions";

// import DataTable from "../../components/DataTable";

const ManageAdmins= (props) => {
  const history = useNavigate();
  const [cookies, setCookie] = useCookies(["blind_weight"]);
  const [adminID, setAdminID] = useState(null);
  const [adminType, setAdminType] = useState(null);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userAccessType, setUserAccessType] = useState("");

  const [userDetails, setUserDetails] = useState([]);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [saveUserLoaded, setSaveUserLoaded] = useState(false);


  const fetchUsers = async () => {
    try {
      if(userDetailsLoading) return;
      setUserDetailsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/admin/getAdminUsers`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // await LogUserActions(user_id, "Manage Users Admins");
        setUserDetails(response.data.userData);
        setUserDetailsLoading(false);
      }
    } catch (error) {
      setUserDetailsLoading(false);
      if (error.response) {
        console.log(error.response);
      } else {
        console.log(error.message);
      }
    }
  };


  useEffect(() => {
    const token = cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
    const decoded = jwt_decode(token);
    if (decoded) {
      setAdminID(decoded.id);
      setAdminType(decoded.user_access);
      if (decoded.user_access !== "admin") {
        history("/dashboard");
      } else if (userDetails.length === 0) {
        fetchUsers();
      }
    }
  }, []);

  const validateUser = () => {
    if (userName.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Name empty",
        text: "Name cannot be empty.",
      });
      return false;
    } else if (userName.length < 3) {
      Swal.fire({
        icon: "error",
        title: "Name too short",
        text: "Name length should be atleast 3 characters.",
      });
      return false;
    } else if (userEmail.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Email empty",
        text: "Email cannot be empty.",
      });
      return false;
    } else if (userType.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select user type.",
      });
      return false;
    } else if (userMobile.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Mobile No empty",
        text: "Please enter user mobile number.",
      });
      return false;
    } else if (userMobile.length < 10) {
      Swal.fire({
        icon: "error",
        title: "Mobile No too short",
        text: "Mobile number should be atleast 10 digits.",
      });
      return false;
    } else if (userAccessType === "") {
      Swal.fire({
        icon: "error",
        title: "User Access empty",
        text: "Please select any one user type",
      });
      return false;
    }
    return true;
  };

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    if (validateUser()) {
      try {
        if (userType === "admin") {
          const deleteUserWarning = await Swal.fire({
            icon: "warning",
            title: `Are you sure to set user to Admin?`,
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
          });
          if (deleteUserWarning.isDenied) {
            return;
          }
        }
        setSaveUserLoaded(false);
        const response = await axios.post(
          `${BASE_URL}/admin/createUser`,
          {
            name: userName.trim(),
            email: userEmail.trim().toLowerCase(),
            user_type: userType,
            mobile_no: userMobile.trim(),
            user_access: userAccessType,
          },
          {
            withCredentials: true,
          },
          {
            headers: {
              Cookie: cookies.blind_weight,
            },
          }
        );
        if (response.status === 200) {
          // console.log(response);
          await LogUserActions(adminID, "Create New User");
          // handleUserFilter();
          fetchUsers();
          setSaveUserLoaded(true);
          // RESET input form values
          setUserName("");
          setUserEmail("");
          setUserType("");
          setUserMobile("");
          setUserAccessType("");

          Swal.fire({
            icon: "success",
            title: "User Added",
            text: response.data.message,
          });
        }
      } catch (error) {
        setSaveUserLoaded(true);
        if (error.response) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          });
        } else {
          console.log(error.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message,
          });
        }
        return;
      }
    }
  };

  // function for delete user (Yet to be implemented)
  const handleDeleteUser = async (e, user_id, name) => {
    e.preventDefault();
    try {
      const deleteUserWarning = await Swal.fire({
        title: `Are you sure to delete this user:: ${name} ?`,
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      });
      if (deleteUserWarning.isDenied) {
        return;
      }
      if (deleteUserWarning.isConfirmed) {
        // API call to delete the user
        const response = await axios.post(
          `${BASE_URL}/admin/deleteUser/${user_id}`,
          {
            admin_id: adminID,
            admin_type: adminType,
          },
          {
            withCredentials: true,
          },
          {
            headers: {
              Cookie: cookies.blind_weight,
            },
          }
        );
        if (response.status === 200) {
          await LogUserActions(adminID, `Delete User:: ${name}`);
          // console.log(response)
          // handleUserFilter()
          fetchUsers();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          return;
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
      return;
    }
  };

  const handleToggleStatus = async (e, user_id) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        `${BASE_URL}/admin/toggleUserStatus`,
        {
          user_id,
        },
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        // handleUserFilter();
        fetchUsers();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  return (
    <>
      <main className='px-4 py-4 justify-content-center'>
        <Row className="m-2">
          <Col className="pt-3">
            <Table bordered striped responsive>
              <thead className='text-center'>
                <tr>
                  <th>Sl.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>User Type</th>
                  <th>Mobile</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className='text-center'>
                {userDetailsLoading ? (
                  <tr className='text-center m-3'>
                    <td colSpan={6}>
                      <Spinner animation='border' />
                    </td>
                  </tr>
                ) : userDetails.length === 0 ? (
                  <tr>
                    <td colSpan={5}>No Data Exists</td>
                  </tr>
                ) : (
                  userDetails.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.user_access}</td>
                      {/* <td>{item.mobile_no}</td> */}
                      <td>{item.mobile_no.replace(/\D/g, "").slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3")}</td>
                      <td>
                        <span title='user status'>
                          {item.status === "invited" ? (
                            "invited"
                          ) : (
                            <Form.Check
                              type='switch'
                              onChange={(e) => handleToggleStatus(e, item._id)}
                              checked={item.status === "active" ? true : false}
                            />
                          )}
                        </span>
                      </td>
                      <td>
                        <Link to={`/edit-admin/${item._id}`}>
                          <FaEdit title='Edit' color='rgb(80,80,255)' />
                        </Link>&nbsp;
                        <FaTrashAlt
                          className="pointer-cursor"
                          onClick={(e) =>
                            handleDeleteUser(
                              e,
                              item._id,
                              item.name,
                              item.user_access
                            )
                          }
                          title='Delete'
                          color='rgb(255,80,80)'
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </main>
    </>
  );
};

export default ManageAdmins;
