import axios from "axios";
import { BASE_URL } from "../config/config";
import { isHTML } from "../utils/utilFunctions";

export const fetchCreditData = async (user_id) => {
	try {
		if (!user_id || typeof user_id === "object") return;
		const response = await axios.post(
			`${BASE_URL}/credit_routes/get-user-credit-details`,
			{ user_id: user_id },
			{
				withCredentials: true,
			}
		);
		return response.data;
	} catch(e) {
		if (e.response) {
			return e.response.data;
		} else {
			return {status: false, message: "Something went wrong."};
		}
	}
};

export const fetchCreditTransactions = async (user_id) => {
	try {
		if (!user_id || typeof user_id === "object") return;
		// console.log("fetch trans: ", user_id)
		const response = await axios.post(
			`${BASE_URL}/credit_routes/get-user-credit-transactions`,
			{ user_id: user_id },
			{
				withCredentials: true,
			}
		);
		return response.data;
	} catch(e) {
		if (e.response) {
			return e.response.data;
		} else {
			return {status: false, message: "Something went wrong."};
		}
	}
};

export const filterParentsAndProvider = async (search_term) => {
	try {
		const response = await axios.get(
			`${BASE_URL}/admin/filterParentsAndProviders?search_term=${search_term}`,
			{
				withCredentials: true,
			}
		);
		return response.data;
} catch(e) {
	if (e.response) {
		return e.response.data;
	} else {
		return {status: false, message: "Something went wrong."};
	}
}
};

export const giveAwayFreeCreditsToUser = async (credits, remark) => {
	try {
		const response = await axios.post(
			`${BASE_URL}/credit_routes/give-away-free-credits-all-users`,
			{
				credits,
				remark,
			},
			{ withCredentials: true }
		);
		return response.data;
	} catch(e) {
		if (e.response) {
			return e.response.data;
		} else {
			return {status: false, message: "Something went wrong."};
		}
	}
};

export const addCreditsToUser = async (user_id, credit_balance, remark) => {
	try {
		const response = await axios.post(
			`${BASE_URL}/admin/updateCreditBalanceOfUser`,
			{
				user_id,
				credit_balance,
				action_type: "add",
				remark: remark,
			},
			{ withCredentials: true }
		);
		return response.data;
	} catch(e) {
		if (e.response) {
			return e.response.data;
		} else {
			return {status: false, message: "Something went wrong."};
		}
	}
};

export const createPaymentIntentForUserCredits = async (user_id, credit_amount) => {
	// create-payment-intent-for-user-credits
	try {
		if (!user_id) return;
		// Create PaymentIntent as soon as the page loads
		const response = await axios.post(
			`${BASE_URL}/payment/create-payment-intent-for-user-credits`,
			{
				items: [{ id: "xl-tshirt" }],
				credit_amount,
				// user_id: "61b04ffebe48a70bb4593761",
				user_id,
			}
		);
		return response.data;
	} catch (e) {
		if (e.response) {
			return e.response.data;
		} else {
			return {status: false, message: "Something went wrong."};
		}
	}
};

export const fetchPaginatedData = async (fetch_url) => {
	let errorResponseObject = { status: false, data: [], total_pages: 1, total_items: 0, page_no: 1, message: "Something went wrong." };
	try {
		if (!fetch_url || fetch_url === "") {
			errorResponseObject.message = "Please provide fetch api url.";
			return errorResponseObject;
		}
		const response = await axios.get(fetch_url,
			{
				withCredentials: true,
			}
		);
		return response.data;
	} catch(e) {
		if (e.response) {
			if (e.response.status === 404 && isHTML(e.response.data)) {
				errorResponseObject.message = "Please enter a valid fetch api url.";
				return errorResponseObject;
			} else {
				return e.response.data;
			}
		} else {
			errorResponseObject.message = "Something went wrong.";
			return errorResponseObject;
		}
	}
}
