import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate, useLocation, Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import "./protected-routes.css";
import { BASE_URL } from "../config/config";
import { LogUserActions } from "../functionalities/loggedActions";
import HeadingNavbar from "./HeadingNavbar/HeadingNavbar";
import SideBar from "./SideBar/SideBar";
import LoaderSpinner from "./LoaderSpinner/LoaderSpinner";

const LazyOutletWrapper = lazy(() => import('./OutletWrapper'));

const ProtectedRoutes = ({ accessible_to=['user'] }) => {
  const history = useNavigate();
  const location = useLocation();
  const headingRef = useRef(null);
  const [headingHeight, setHeadingHeight] = useState(50);
  const [isLoaded, setIsLoaded] = useState(false);
  const [authDetails, setAuthDetails] = useState(null);
  const [auth, setAuth] = useState(false);

  const [cookies, removeCookie] = useCookies(["blind_weight"]);

  useEffect(() => {
    let auth_status = false;
    if (!cookies.blind_weight) {
      setAuth(false);
      history("/");
      auth_status = false;
      return;
    } else if (cookies.blind_weight !== "undefined" && !isLoaded) {
      const token = cookies.blind_weight ? cookies.blind_weight : null;
      if (token) {
        const decoded = jwt_decode(token);
        if (decoded) {
          if (auth === false) {
            setAuthDetails(decoded);
            setAuth(true);
            auth_status = true;
          }

          if (decoded.exp * 1000 < Date.now()) {
            console.log("Token expired");
            auth_status = false;
            setAuth(false);
            setAuthDetails(null);
            removeCookie("blind_weight");
            history("/");
            return;
          }
        }
      }
    }
    if(!auth_status){
      history("/");
      return;
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if(headingRef.current !== null){
      setHeadingHeight(headingRef.current.clientHeight);
    }
  });

  useEffect(() => {
    if (location.pathname && authDetails) {
      if (!accessible_to.includes(authDetails.user_access)) history("/");
    }
  },[location, authDetails]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const warning = await Swal.fire({
        title: "Are you sure, you want to Logout?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      });

      if (warning.isConfirmed) {
        const response = await axios.post(`${BASE_URL}/user/logoutUser`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          authDetails !== null &&
            (await LogUserActions(authDetails.id, "Logout User"));
          setAuth(false);
          setAuthDetails(null);
          removeCookie("blind_weight");
          history("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return isLoaded ? (
    auth ? (
      <main style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
        <HeadingNavbar
          ref={headingRef}
          onClick={handleLogout}
          userName={authDetails.name}
          userType={authDetails.user_access}
        />
        <div className='content' style={{  height: `calc(100vh - ${headingHeight}px)`, width: "100%" }}>
          <SideBar
            userName={authDetails.name}
            userType={authDetails.user_access}
            handleLogout={handleLogout}
          />
          <div className='dashboard-main' style={{ height: "100%", width: "100%", overflowX: "hidden", overflowY: "scroll" }}>
            <Suspense fallback={<div className="mt-5"><LoaderSpinner /></div>}>
              <LazyOutletWrapper />
            </Suspense>
          </div>
        </div>
      </main>
    ) : (
      <Navigate to='/' />
    )
  ) : (
    <div className='d-flex justify-content-center align-center mt-4'>
      <LoaderSpinner />
    </div>
  );
};

export default ProtectedRoutes;
