import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import { BASE_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { LogUserActions } from "../../functionalities/loggedActions";
import { useRef } from "react";

const Support = (props) => {
  const history = useNavigate();
  const [cookies] = useCookies(["blind_weight"]);
  const issueFileRef = useRef(null);

  const [userID, setUserID] = useState(null);
  const [issueTitle, setIssueTitle] = useState("account issue");
  const [issueMessage, setIssueMessage] = useState("");
  const [issueFile, setIssueFile] = useState(null);
  const [issueFiles, setIssueFiles] = useState(null);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const token =
    cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
  const decoded = jwt_decode(token);

  useEffect(() => {
    if (decoded) {
      setUserID(decoded.id);
      // if (decoded.user_type !== "admin") {
      //   history("/dashboard");
      //   return;
      // }
      // Mount function calls
    }else{
        history("/login");
        return;
    }
  }, []);

  const validationCheck = () => {
    let status = true;
    if(issueTitle === "" || issueMessage === ""){
      Swal.fire({ icon:"error",title:"Error",text:"Please enter all text fields" });
      status = false;
    }
    return status;
  };

  const handleSubmitIssue = async (e) => {
    try {
      if (sendMessageLoading) return;
      // Validation function call
      if (!validationCheck()) {
        return;
      }

      // Only for multiple file uploads (Currently not used)
      // const uploaded = [];
      // if(issueFiles !== null){
      //   const checkLimitExceeded = issueFiles.some((file) => {
      //     if(uploaded.findIndex((f) => f.name === file.name) === -1){
      //       uploaded.push(file);
      //       if(uploaded.length > 3){
      //         Swal.fire({
      //           icon: "error",
      //           title: "Error",
      //           text: "You cannot upload more than 3 files...!!!"
      //         });
      //         return true;
      //       }
      //     }
      //     return false;
      //   });
      //   if(checkLimitExceeded) return;
      // }

      var formData = new FormData();
      formData.append("issue_title", issueTitle);
      formData.append("issue_message", issueMessage);
      formData.append("issue_file", issueFile);

      // for(var i = 0 ; i < issueFiles.length; i++){
      //   formData.append('issue_files',issueFiles[i])
      // }

      setSendMessageLoading(true);
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/user/submit-issue`,
        data: formData,
        withCredentials: true,
        header: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Cookie: cookies.blind_weight,
        },
      });

      if (response.status === 200) {
        let log_message = "Issued new " + issueTitle;
        await LogUserActions(userID, log_message);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
        setIssueMessage("");
        setIssueFile(null);
        issueFileRef.current.value = null;
        // setIssueFiles(null);
      }
      setSendMessageLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong.",
        });
      }
      setSendMessageLoading(false);
    }
  };

  const handleIssueFilesEvent = (e) => {
    // For multiple uploads
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setIssueFiles(chosenFiles);
  };

  return (
    <main className="px-4 mb-4 justify-content-center">
      <Row className="p-2">
        <Col className="pt-3 d-flex">
          <h3>Having any Issues?</h3>
        </Col>
      </Row>
      <Row className='p-2 d-flex justify-content-center'>
        <Col md={6} sm={12}>
          <FormGroup className="mb-2">
            <Form.Label>Select Issue:</Form.Label>
            <Form.Select
              value={issueTitle}
              onChange={(e) => setIssueTitle(e.target.value)}
            >
              <option value="account issue">Account</option>
              <option value="billing issue">Billing</option>
              <option value="suggestion">Suggestions</option>
              <option value="message">Others</option>
            </Form.Select>
          </FormGroup>
          <FormGroup className='mb-2'>
            <Form.Label>Write your message:</Form.Label>
            <Form.Control
              value={issueMessage}
              onChange={(e) => setIssueMessage(e.target.value)}
              as="textarea"
              rows={6}
              placeholder="Please write your issue in here"
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md={6} sm={12}>
          <FormGroup className="mb-2">
            <Form.Label>
              Upload File(<em>Supports only PNG and JPEG formats</em>):
            </Form.Label>
            <Form.Control ref={issueFileRef} onChange={(e) => {
                e.persist();
                setIssueFile(e.target.files[0]);
              }} type='file'></Form.Control>
            {/* <Form.Control onChange={(e) => handleIssueFilesEvent(e)} type='file' multiple></Form.Control> */}
          </FormGroup>
        </Col>
      </Row>
      <Row className="px-2">
        <Col>
          <Button className="mb-2 w-4" onClick={(e) => handleSubmitIssue(e)}>
            {sendMessageLoading ? <Spinner animation="border" size="sm" /> : 'Submit Issue' }
          </Button>
        </Col>
      </Row>
    </main>
  );
};

export default Support;
