import { motion } from "framer-motion";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRegTrashAlt, FaArrowAltCircleRight } from "react-icons/fa";
import { FiDelete } from "react-icons/fi";

import "./onscreen.styles.css";

import letterO from "../../assets/img/codes/Letter-O.png";
import letterJ from "../../assets/img/codes/Letter-J.png";
import letterU from "../../assets/img/codes/Letter-U.png";
import letterL from "../../assets/img/codes/Letter-L.png";
import letterF from "../../assets/img/codes/Letter-F.png";
import letterE from "../../assets/img/codes/Letter-E.png";
import letterC from "../../assets/img/codes/Letter-C.png";
import letterP from "../../assets/img/codes/Letter-P.png";
import letterS from "../../assets/img/codes/Letter-S.png";
import letterG from "../../assets/img/codes/Letter-G.png";
import letterd from "../../assets/img/codes/Letter-d.png";
import letterb from "../../assets/img/codes/Letter-b.png";

export const alphaImgUrls = [
  { letter: "O", text: "Uppercase O", imgUrl: letterO },
  { letter: "J", text: "Uppercase J", imgUrl: letterJ },
  { letter: "U", text: "Uppercase U", imgUrl: letterU },
  { letter: "L", text: "Uppercase L", imgUrl: letterL },
  { letter: "F", text: "Uppercase F", imgUrl: letterF },
  { letter: "E", text: "Uppercase E", imgUrl: letterE },
  { letter: "C", text: "Uppercase C", imgUrl: letterC },
  { letter: "P", text: "Uppercase P", imgUrl: letterP },
  { letter: "S", text: "Uppercase S", imgUrl: letterS },
  { letter: "G", text: "Uppercase G", imgUrl: letterG },
  { letter: "d", text: "Lowercase d", imgUrl: letterd },
  { letter: "b", text: "Lowercase b", imgUrl: letterb },
];

const keys_group_1 = ["J", "b", "L", "P", "C", "E", "U"];
const keys_group_2 = ["S", "d", "O", "G", "F"];
const actionkeys = ["DELETE", "CLEAR", "DECODE"];

const OnscreenKeyboard = ({
  variant = "light-transparent",
  onPressButton,
  buttonVariant = "primary",
  showDecode = true,
}) => {
  return (
    <div
      style={
        variant === "light-transparent"
          ? lightTransparentStyles
          : variant === "light"
          ? lightStyles
          : variant === "dark-transparent"
          ? darkTransparentStyles
          : variant === "dark" && darkStyles
      }
    >
      <div className="mt-2">
        {keys_group_1.map((k, idx) => (
          <span key={idx} className="p-1">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {alphaImgUrls.find((el) => el.letter === k).text}
                </Tooltip>
              }
            >
              <motion.button
                whileHover={{ scale: 1.07 }}
                variant="light"
                onClick={() => onPressButton(k)}
                className="btn btn-light my-1 p-2 input-button"
              >
                {alphaImgUrls ? (
                  <Image
                    src={alphaImgUrls.find((el) => el.letter === k).imgUrl}
                    style={{ height: "2.2em" }}
                  />
                ) : (
                  k
                )}
              </motion.button>
            </OverlayTrigger>
          </span>
        ))}
      </div>
      <div>
        {keys_group_2.map((k, idx) => (
          <span key={idx} className="p-1">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {alphaImgUrls.find((el) => el.letter === k).text}
                </Tooltip>
              }
            >
              <motion.button
                whileHover={{ scale: 1.07 }}
                onClick={() => onPressButton(k)}
                className="btn btn-light my-1 p-2 input-button"
              >
                {alphaImgUrls ? (
                  <Image
                    src={alphaImgUrls.find((el) => el.letter === k).imgUrl}
                    style={{ height: "2.2em" }}
                  />
                ) : (
                  k
                )}
              </motion.button>
            </OverlayTrigger>
          </span>
        ))}
      </div>
      <div>
        {actionkeys.map((k, idx) => (
          <span
            key={idx}
            className={`p-1 ${!showDecode && k === "DECODE" && "d-none"}`}
          >
            <motion.button
              whileHover={{ scale: 1.07 }}
              onClick={() => onPressButton(k)}
              className={`btn btn-${
                k === "DECODE" ? "primary" : buttonVariant
              } my-1`}
              style={{
                // fontSize: "28px",
                fontSize: "1em",
                fontWeight: "800",
                fontFamily: "Digital-Counter-7",
              }}
            >
              {k === "DELETE" ? (
                <span
                  className="d-flex justify-items-center justify-content-center align-items-center"
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  <FiDelete />
                  &nbsp;&nbsp;{k}
                </span>
              ) : k === "CLEAR" ? (
                <span
                  className="d-flex justify-items-center justify-content-center align-items-center"
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  <FaRegTrashAlt />
                  &nbsp;&nbsp;{k}
                </span>
              ) : k === "DECODE" ? (
                <span
                  className="d-flex justify-items-center justify-content-center align-items-center"
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  <FaArrowAltCircleRight />
                  &nbsp;&nbsp;{k}
                </span>
              ) : (
                k
              )}
            </motion.button>
          </span>
        ))}
      </div>
    </div>
  );
};

const lightStyles = {
  color: "#000",
  backgroundColor: "#fff",
};
const darkStyles = {
  color: "#fff",
  backgroundColor: "#000",
};
const lightTransparentStyles = {
  color: "#fff",
  backgroundColor: "transparent",
};
const darkTransparentStyles = {
  color: "#000",
  backgroundColor: "transparent",
};

export default OnscreenKeyboard;
