import React, { useEffect, useState } from "react";
import {
  // AddressElement,
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { FormGroup, Form, Button, Row, Col, Spinner, Modal } from "react-bootstrap";
import { BASE_URL, CLIENT_URL } from "../../config/config";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

export default function CheckoutForm({ returnAction, payment_type="registration", productPrice, userName, user_id, pageRoute }){
  const location = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [cookies, setCookie] = useCookies(["blind_weight"]);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showParentPayInfo, setShowParentPayInfo] = useState(false);

  const [productPriceState, setProductPriceState] = useState(10);

  const [userID, setUserID] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [invalidName, setInvalidName] = useState(false);
  const [invalidCity, setInvalidCity] = useState(false);
  const [invalidLine1, setInvalidLine1] = useState(false);
  const [invalidLine2, setInvalidLine2] = useState(false);
  // const [invalidCountryCode, setInvalidCountryCode] = useState(false);
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [invalidStateCode, setInvalidStateCode] = useState(false);

  const [invalidNameMessage, setInvalidNameMessage] = useState("");
  const [invalidCityMessage, setInvalidCityMessage] = useState("");
  const [invalidLine1Message, setInvalidLine1Message] = useState("");
  const [invalidLine2Message, setInvalidLine2Message] = useState("");
  // const [invalidCountryCodeMessage, setInvalidCountryCodeMessage] = useState("");
  const [invalidPostalCodeMessage, setInvalidPostalCodeMessage] = useState("");
  const [invalidStateCodeMessage, setInvalidStateCodeMessage] = useState("");

  useEffect(() => {
    if (userName !== "") {
      setUserID(user_id);
      setName(userName);
    }
  }, [userName,user_id]);

  useEffect(() => {
    if(productPrice){
      setProductPriceState(productPrice);
    }
  }, [productPrice]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if(!clientSecret){
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(async({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          await handleSuccessfullPayment();
          let redirectURL = "";
          if(pageRoute){
            let route = pageRoute.slice(1);
            redirectURL = `/payment-success/${userID}/${payment_type}/${route}`;
          }else{
            redirectURL = `/payment-success/${userID}/${payment_type}/sign-up/`;
          }
          navigate(redirectURL);
          returnAction && returnAction();
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const validateAddress = () => {
    let status = true;

    if (name === "") {
      // Please enter your name
      setInvalidName(true);
      setInvalidNameMessage("Please enter your name");
      status = false;
    }else{
      setInvalidName(false);
    }
    if (city === "") {
      // Please enter your city
      setInvalidCity(true);
      setInvalidCityMessage("Please enter your city");
      status = false;
    }else{
      setInvalidCity(false);
    }
    if (line1 === "") {
      // Please enter line of address
      setInvalidLine1(true);
      setInvalidLine1Message("Please enter line1 of address");
      status = false;
    }else{
      setInvalidLine1(false);
    }
    // if (line2 === "") {
    //   // Please enter line 2 of address
    //   setInvalidLine2(true);
    //   setInvalidLine2Message("Please enter line2 of address");
    //   status = false;
    // }else{
    //   setInvalidLine2(false);
    // }
    if (postalCode === "") {
      // Please enter your postal code
      setInvalidPostalCode(true);
      setInvalidPostalCodeMessage("Please enter postal code");
      status = false;
    }else{
      setInvalidPostalCode(false);
    }
    if (stateCode === "") {
      // Please enter your state code
      setInvalidStateCode(true);
      setInvalidStateCodeMessage("Please enter state code");
      status = false;
    }else{
      setInvalidStateCode(false);
    }

    return status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!validateAddress()) return;
    if(userID === "") return;
    let redirectURL = "";
    // let redirectURL = `${CLIENT_URL}/payment-success/${userID}`;
    if(pageRoute){
      let route = pageRoute.slice(1);
      redirectURL = `${CLIENT_URL}/payment-success/${userID}/${payment_type}/${route}`;
    }else{
      redirectURL = `${CLIENT_URL}/payment-success/${userID}/${payment_type}/sign-up`;
    }

    setIsLoading(true);

    
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: redirectURL,
        shipping: {
          name: name,
          address: {
            city: city,
            country: "US",
            line1: line1,
            line2: line2,
            postal_code: postalCode,
            state: stateCode,
          },
        },
      },
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    
    if(error){
      if (
        error.type === "card_error" ||
        error.type === "validation_error"
      ) {
        setMessage(error.message);
        // await handleFailedRegistrationPayment();
      } else {
        console.log("error");
        console.log(error);
        setMessage("An unexpected error occurred.");
        // await handleFailedRegistrationPayment();
      }
    }else{
      await handleSuccessfullPayment();
    }

    setIsLoading(false);
  };
  

  const handleSuccessfullPayment = async() => {
    try{
      const response = await axios.post(`${BASE_URL}/payment/registration-success`,{
        user_id: userID,
        payment_type: payment_type,
      })
      if(response.status === 200){
        // console.log(response);
        setCookie("blind_weight", response.data.token);

        if(pageRoute){
          let route = pageRoute.slice(1);
          navigate(`/payment-success/${userID}/${payment_type}/${route}`);
        }else{
          navigate(`/payment-success/${userID}/${payment_type}/`);
        }

      }

    }catch(e){
      if(e.response){
        console.log(e.response);
      }else{
        console.log(e);
      }
    }
  }

  return (
    <>
      <Modal
        size="md"
        show={showParentPayInfo}
        onHide={() => {
          setShowParentPayInfo(false);
        }}
      >
        <Modal.Body>

          <p style={{ fontSize: "14px" }}>
            As a registered parent, you can access our portal for a one-time activation fee of $20, enabling you to create and verify your account automatically. With this account, you can securely access and decode the scale display codes to retrieve weigh-in measurements. 
          </p>
          
        </Modal.Body>

        <Modal.Footer>
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setShowParentPayInfo(false);
              }}
              size="sm"
            >
              Close
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <form id='payment-form'>
      {/* <form id='payment-form' onSubmit={handleSubmit}> */}
        <div className="w-100 bg-info rounded border p-2 mb-2" style={{ fontSize: "25px" }}>
          <div>
            {payment_type === "registration" ? "One-Time Registration Fee for Parent Accounts:" : "Payment for User Credits:"}&nbsp;
            ${productPriceState?productPriceState:20}
          </div>
          {payment_type === "registration" && (
            <span className="pointer-cursor text-white" onClick={() => setShowParentPayInfo(true)} style={{ fontSize: "14px" }}>Learn More</span>
          )}
        </div>
        <PaymentElement id='payment-element' />
        <FormGroup className='mb-2 mt-2 w-100'>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            isInvalid={invalidName}
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Enter name'
          />
          {invalidName && (
            <Form.Control.Feedback type="invalid">{invalidNameMessage}</Form.Control.Feedback>
          )}
        </FormGroup>
        <FormGroup className='mb-2 w-100'>
          <Form.Label>City:</Form.Label>
          <Form.Control
            type='text'
            isInvalid={invalidCity}
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder='Enter city'
          />
          {invalidCity && (
            <Form.Control.Feedback type="invalid">{invalidCityMessage}</Form.Control.Feedback>
          )}
        </FormGroup>
        <FormGroup className='mb-2 w-100'>
              <Form.Label>Address Line1:</Form.Label>
          <Form.Control
            type='text'
            isInvalid={invalidLine1}
            value={line1}
            onChange={(e) => setLine1(e.target.value)}
            placeholder='Enter address line 1'
          />
          {invalidLine1 && (
            <Form.Control.Feedback type="invalid">{invalidLine1Message}</Form.Control.Feedback>
          )}
        </FormGroup>
        <FormGroup className='mb-2 w-100'>
          <Form.Label>Address Line2:</Form.Label>
          <Form.Control
            type='text'
            isInvalid={invalidLine2}
            value={line2}
            onChange={(e) => setLine2(e.target.value)}
            placeholder='Enter address line 2'
          />
          {invalidLine2 && (
            <Form.Control.Feedback type="invalid">{invalidLine2Message}</Form.Control.Feedback>
          )}
        </FormGroup>
        <Row className="w-100 p-0 m-0">
          <Col className="ps-0">
            <FormGroup className='mb-2 w-100'>
              <Form.Label>State Code:</Form.Label>
              <Form.Control
                type='text'
                isInvalid={invalidStateCode}
                value={stateCode}
                onChange={(e) => setStateCode(e.target.value)}
                placeholder='Enter your state code'
              />
              {invalidStateCode && (
                <Form.Control.Feedback type="invalid">{invalidStateCodeMessage}</Form.Control.Feedback>
              )}
            </FormGroup>
          </Col>
          <Col className="pe-0">
            <FormGroup className='mb-2 w-100'>
              <Form.Label>Postal Code:</Form.Label>
              <Form.Control
                type='text'
                isInvalid={invalidPostalCode}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder='Enter your postal code '
              />
              {invalidPostalCode && (
                <Form.Control.Feedback type="invalid">{invalidPostalCodeMessage}</Form.Control.Feedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Button
          disabled={isLoading || !stripe || !elements}
          id='submit'
          onClick={handleSubmit}
          className="w-100"
        >
          <span id='button-text'>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Pay now"
            )}
          </span>
        </Button>
        {/* Show any error or success messages */}
        {message && <div id='payment-message'>{message}</div>}
      </form>
    </>
  );
}
