import React from "react";

import "./loader-spinner.styles.css";

const LoaderSpinner = ({ heightWidth = 100, showText = true, textFontSize = 15 }) => {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div style={{ height: `${heightWidth}px`, width: `${heightWidth}px` }} className='loader-spinner-div d-flex justify-content-center align-items-center'>
          <span className={`loader-text ${showText ? "d-block" : "d-none" }`} style={{ fontSize: `${textFontSize}px` }}>Loading...</span>
      <div className='loader-spinner'></div>
      </div>
    </div>
  );
};

export default LoaderSpinner;
