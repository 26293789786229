import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { AnimatePresence, motion } from "framer-motion";
import { Bar, Line } from "react-chartjs-2";
import { FiArrowLeftCircle } from "react-icons/fi";
import {
  FaCheckCircle,
  FaEye,
  FaPlusCircle,
  FaRegCheckCircle,
  FaSquare,
  FaTrashAlt,
} from "react-icons/fa";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { BASE_URL } from "../../config/config";
import { divideArrayByProperty, getChartLabelSeriesFor2Dates } from "../../utils/utilFunctions";

const UserSignupsChart = ({
  heading_text = "",
  showHeadingText = false,
  changeParentModalStatus,
}) => {
  const [chartData, setChartData] = useState(null);
  const [chartDataLoading, setChartDataLoading] = useState(false);

  const [chartFromDate, setChartFromDate] = useState(
    moment().subtract(2, "months").format("YYYY-MM-DD")
  );
  const [chartToDate, setChartToDate] = useState(moment().format("YYYY-MM-DD"));
  const [chartUserAccess, setChartUserAccess] = useState("all");
  const [chartDisplayFormat, setChartDisplayFormat] = useState("monthly");
  const [chartBgColor, setChartBgColor] = useState("#FF6384");
  const [chartBorderColor, setChartBorderColor] = useState("#FF6384");
  const [chartErrorMessage, setChartErrorMessage] = useState("");
  const [chartType, setChartType] = useState("v-bar");

  const [viewChartSettingsList, setViewChartSettingsList] = useState(false);
  const [chartListLoading, setChartListLoading] = useState(false);
  const [chartSettingsList, setChartSettingsList] = useState([]);
  const [activeChartSetting, setActiveChartSetting] = useState(null);
  const [defaultChartSettingLoading, setDefaultChartSettingLoading] =
    useState(false);

  // useEffect(() => {
  //   getDefaultChartSetting();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (activeChartSetting !== null) {
      setChartFromDate(
        moment(activeChartSetting.from_date).format("YYYY-MM-DD")
      );
      setChartToDate(moment(activeChartSetting.to_date).format("YYYY-MM-DD"));
      setChartUserAccess(activeChartSetting.user_access);
      setChartDisplayFormat(activeChartSetting.display_format);
      setChartBgColor(activeChartSetting.chart_color);
      setChartBorderColor(activeChartSetting.border_color);
    }
  }, [activeChartSetting]);

  const handleOnchangeActiveValue = () => {
    if (activeChartSetting) {
      setActiveChartSetting(null);
    }
  };

  const fetchChartDetails = async (e) => {
    try {
      e.preventDefault();
      if (chartDataLoading) return;

      setChartDataLoading(true);
      const response = await axios.post(
        `${BASE_URL}/admin/getUserCharts`,
        {
          from_date: moment(chartFromDate).toISOString(),
          to_date: moment(chartToDate).toISOString(),
          user_access: chartUserAccess,
          display_format: chartDisplayFormat,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // console.log(response);
        let labelArray = [];

        if (response.data.userData.length > 0) {
          setChartErrorMessage("");
          const labelSeriesArray = getChartLabelSeriesFor2Dates(
            chartFromDate,
            chartToDate,
            chartDisplayFormat
          );
          let monthArray = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          labelSeriesArray.forEach((user) => {
            if (chartDisplayFormat === "yearly") {
              labelArray.push(`${user.year}`);
            }
            if (chartDisplayFormat === "monthly") {
              let monthValue = user.month;
              labelArray.push(
                `${monthArray[monthValue - 1].slice(0, 3)}' ${user.year
                  .toString()
                  .slice(2, 4)}`
              );
            }
            // if (chartDisplayFormat === "weekly") {
            //   let monthValue = user.month;
            //   labelArray.push(
            //     `${user.week}' ${user.year
            //       .toString()
            //       .slice(2, 4)}`
            //   );
            // }
            if (chartDisplayFormat === "daily") {
              let monthValue = user.month;
              // console.log("user: ",user)
              labelArray.push(
                `${user.day} ${monthArray[monthValue - 1].slice(
                  0,
                  3
                )}' ${user.year.toString().slice(2, 4)}`
              );
            }
          });

          let responseData = response.data.userData;

          const userAccessGroupedObject = divideArrayByProperty(
            responseData,
            "user_access"
          );
          
          let dataSetArray = [];
          for (let key in userAccessGroupedObject) {
            if (userAccessGroupedObject.hasOwnProperty(key)) {
              const value = userAccessGroupedObject[key];

              let dataSetForThisInitial = [];

              labelSeriesArray.forEach((labelItem) => {
                let thisYearExists, thisMonthExists, thisDayExists;
                if (chartDisplayFormat === "yearly") {
                  // check if for this key initail, this month and year data exists or not
                  thisYearExists = value.find((obj) => {
                    if (
                      obj._id.year === labelItem.year &&
                      obj.user_access === key
                    ) {
                      return obj;
                    } else {
                      return null;
                    }
                  });
                }
                if (chartDisplayFormat === "monthly") {
                  // check if for this key initail, this month and year data exists or not
                  thisMonthExists = value.find((obj) => {
                    if (
                      obj._id.month === labelItem.month &&
                      obj._id.year === labelItem.year &&
                      obj.user_access === key
                    ) {
                      return obj;
                    } else {
                      return null;
                    }
                  });
                }
                if (chartDisplayFormat === "daily") {
                  thisDayExists = value.find((obj) => {
                    if (
                      obj._id.day === labelItem.day &&
                      obj._id.month === labelItem.month &&
                      obj._id.year === labelItem.year &&
                      obj.user_access === key
                    ) {
                      return obj;
                    } else {
                      return null;
                    }
                  });
                }

                if (thisYearExists && chartDisplayFormat === "yearly") {
                  dataSetForThisInitial.push(thisYearExists.user_count);
                } else if (thisMonthExists && chartDisplayFormat === "monthly") {
                  dataSetForThisInitial.push(thisMonthExists.user_count);
                } else if (thisDayExists && chartDisplayFormat === "daily") {
                  dataSetForThisInitial.push(thisDayExists.user_count);
                } else {
                  dataSetForThisInitial.push(0);
                }

              });
              let labelValue = key;
              if (key === "null") labelValue = "NA";
              dataSetArray.push({
                label: labelValue,
                data: dataSetForThisInitial,
              });
            }
          }
          // console.log(dataSetArray)

          setChartData({
            labels: labelArray,
            datasets: dataSetArray,
          });
        } else {
          setChartData(null);
          setChartErrorMessage("No data exists with selected parameters");
        }

        setChartDataLoading(false);
      }
    } catch (e) {
      setChartDataLoading(false);
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const getDefaultChartSetting = async () => {
    try {
      // e.preventDefault();
      if (defaultChartSettingLoading) return;
      setDefaultChartSettingLoading(true);
      const response = await axios.get(
        `${BASE_URL}/setting/getUserDefaultChartSetting`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setActiveChartSetting(response.data.chart_setting);
        setDefaultChartSettingLoading(false);
      }
    } catch (e) {
      setDefaultChartSettingLoading(false);
    }
  };

  const handleSaveChartSetting = async () => {
    try {
      // modalChartSettingRef.current && modalChartSettingRef.current.blur();
      // setShowUserChartModal(false);
      changeParentModalStatus && changeParentModalStatus(false);
      const settingInfo = await Swal.fire({
        title: "Setting Initials",
        icon: "warning",
        input: "text",
        inputPlaceholder: "Enter setting initails",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          if (value === "") {
            return Swal.showValidationMessage(
              "Setting Initials cannot be empty"
            );
          } else if (value.length < 3) {
            return Swal.showValidationMessage(
              "Setting initials must be atleast 3 charaters in length"
            );
          }
          return value;
        },
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
      });
      // console.log(settingInfo)
      // setShowUserChartModal(true);
      changeParentModalStatus && changeParentModalStatus(true);
      if (!settingInfo.isConfirmed) {
        return;
      }
      let setting_initials = settingInfo.value;
      // modalChartSettingRef.current && modalChartSettingRef.current.focus();

      const response = await axios.post(
        `${BASE_URL}/setting/saveUserChartSetting`,
        {
          from_date: moment(chartFromDate).toISOString(),
          to_date: moment(chartToDate).toISOString(),
          user_access: chartUserAccess,
          display_format: chartDisplayFormat,
          chart_color: chartBgColor,
          border_color: chartBorderColor,
          setting_initials: setting_initials,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setActiveChartSetting(response.data.chart_setting);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const fetchAllChartSettings = async () => {
    try {
      if (chartListLoading) return;
      setChartListLoading(true);
      const response = await axios.get(
        `${BASE_URL}/setting/getUserAllChartSetting`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setChartData(null);
        setChartSettingsList(response.data.chart_settings);
        setViewChartSettingsList(true);
        setChartListLoading(false);
      }
    } catch (e) {
      setChartListLoading(false);
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const getCurrentChartSetting = async (e, chart_setting_id) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        `${BASE_URL}/setting/getUserCurrentChartSetting`,
        {
          chart_setting_id: chart_setting_id,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setActiveChartSetting(response.data.chart_setting);
      }
    } catch (e) {
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleChangeDefaultChartSetting = async (
    e,
    chart_setting_id,
    status
  ) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        `${BASE_URL}/setting/changeDefaultUserChartSetting`,
        {
          chart_setting_id: chart_setting_id,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        fetchAllChartSettings();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleDeleteChartSetting = async (e, chart_setting_id) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        `${BASE_URL}/setting/deleteUserCurrentChartSetting`,
        {
          chart_setting_id: chart_setting_id,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        fetchAllChartSettings();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  return (
    <div className="w-100">
      {showHeadingText && (
        <Row className="w-100">
          <Col className="w-100 mb-2">
            <h6>{showHeadingText ? heading_text : "User Signup Chart"}</h6>
          </Col>
        </Row>
      )}

      <Row className="p-0 m-0 mb-3">
        {!viewChartSettingsList ? (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <Col className="p-0 mb-0 d-flex justify-content-end">
              {activeChartSetting && (
                <div className="me-3 border rounded px-2 d-flex justify-content-center align-items-center">
                  <span style={{ fontWeight: "500", fontSize: "18px" }}>
                    {activeChartSetting.setting_initials}
                  </span>
                </div>
              )}
              {/* <Button
                className="me-2"
                onClick={handleSaveChartSetting}
                title="Add this chart setting in existing chart settings"
              >
                <FaPlusCircle /> Add this Chart Setting
              </Button>
              <Button className="me-2" onClick={fetchAllChartSettings} title="View all chart settings">
                <FaEye /> View Chart Settings
              </Button> */}
            </Col>
          </motion.div>
        ) : (
          <Col className="p-0 mb-0 d-flex justify-content-between">
            <Button
              variant="secondary"
              className="me-2"
              onClick={() => setViewChartSettingsList(false)}
              title="Back to chart generation"
            >
              <FiArrowLeftCircle />
            </Button>
            {activeChartSetting && (
              <div className="d-flex justify-content-center align-items-center">
                <span style={{ fontWeight: "500", fontSize: "18px" }}>
                  {activeChartSetting.setting_initials}
                </span>
              </div>
            )}
            <div></div>
          </Col>
        )}
      </Row>

      <AnimatePresence>
        {!viewChartSettingsList ? (
          <>
            <motion.div
            key="settings"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
            >
              <Row className="p-0 m-0">
                {defaultChartSettingLoading ? (
                  <Col className="text-center">
                    <Spinner animation="border" size="lg" />
                  </Col>
                ) : (
                  <>
                    <Col xs={12} sm={12} md={6} lg={3}>
                      <FormGroup className="mb-2">
                        <Form.Label>From Date:</Form.Label>
                        <Form.Control
                          type="date"
                          value={chartFromDate}
                          onChange={(e) => {
                            handleOnchangeActiveValue();
                            setChartFromDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3}>
                      <FormGroup className="mb-2">
                        <Form.Label>To Date:</Form.Label>
                        <Form.Control
                          type="date"
                          value={chartToDate}
                          onChange={(e) => {
                            handleOnchangeActiveValue();
                            setChartToDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3}>
                      <FormGroup className="mb-2">
                        <Form.Label>User Access:</Form.Label>
                        <Form.Select
                          value={chartUserAccess}
                          onChange={(e) => {
                            handleOnchangeActiveValue();
                            setChartUserAccess(e.target.value);
                          }}
                        >
                          <option value="all">All</option>
                          <option value="provider">Provider</option>
                          <option value="parent">Parent</option>
                          <option value="user">User</option>
                        </Form.Select>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3}>
                      <FormGroup className="mb-2">
                        <Form.Label>Display Format:</Form.Label>
                        <ButtonGroup>
                          <Button
                            variant={`${
                              chartDisplayFormat === "yearly"
                                ? "primary"
                                : "secondary"
                            }`}
                            onClick={() => {
                              handleOnchangeActiveValue();
                              setChartDisplayFormat("yearly");
                            }}
                          >
                            Yearly
                          </Button>
                          <Button
                            variant={`${
                              chartDisplayFormat === "monthly"
                                ? "primary"
                                : "secondary"
                            }`}
                            onClick={() => {
                              handleOnchangeActiveValue();
                              setChartDisplayFormat("monthly");
                            }}
                          >
                            Monthly
                          </Button>
                          {/* <Button
                          variant={`${
                            chartDisplayFormat === "weekly" ? "primary" : "secondary"
                          }`}
                          onClick={() => {
                            setChartDisplayFormat("weekly");
                          }}
                        >
                          Weekly
                        </Button> */}
                          <Button
                            variant={`${
                              chartDisplayFormat === "daily" ? "primary" : "secondary"
                            }`}
                            onClick={() => {
                              setChartDisplayFormat("daily");
                            }}
                          >
                            Daily
                          </Button>
                        </ButtonGroup>
                      </FormGroup>
                    </Col>
                    {/* <Col xs={12} sm={12} md={6} lg={3}>
                      <FormGroup className="mb-2">
                        <Form.Label>Chart Color:</Form.Label>
                        <Form.Control
                          type="color"
                          value={chartBgColor}
                          onChange={(e) => {
                            handleOnchangeActiveValue();
                            setChartBgColor(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3}>
                      <FormGroup className="mb-2">
                        <Form.Label>Border Color:</Form.Label>
                        <Form.Control
                          type="color"
                          value={chartBorderColor}
                          onChange={(e) => {
                            handleOnchangeActiveValue();
                            setChartBorderColor(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col> */}
                    <Col xs={12} sm={12} md={6} lg={3} className="pt-2">
                      <FormGroup className="mb-2">
                        <Form.Label>Chart Type:</Form.Label>
                        <Form.Select
                          value={chartType}
                          onChange={(e) => {
                            setChartType(e.target.value);
                          }}
                        >
                          <option value="line">Line</option>
                          <option value="area">Area</option>
                          <option value="v-bar">Vertical Bar</option>
                          {/* <option value="h-bar">Horizontal Bar</option> */}
                        </Form.Select>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3} className="pt-2">
                      <Button className="mt-4" onClick={fetchChartDetails}>
                        {chartDataLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Create Chart"
                        )}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </motion.div>
          </>
        ) : (
          <>
            <motion.div
            key="table"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
            >
              <Row className="p-0 m-0">
                <Col>
                  <Table bordered striped responsive>
                    <thead className="text-center">
                      <tr>
                        <th>Sl.No.</th>
                        <th>Setting Initials</th>
                        <th>Default</th>
                        <th>From</th>
                        <th>To</th>
                        <th>User Access</th>
                        <th>Display Format</th>
                        <th>Chart Color</th>
                        <th>Border Color</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chartSettingsList.length === 0 ? (
                        <tr>
                          <td colSpan={10}>No Settings Exist</td>
                        </tr>
                      ) : chartListLoading ? (
                        <tr className="text-center">
                          <td colSpan={10}>
                            <Spinner animation="border" />
                          </td>
                        </tr>
                      ) : (
                        chartSettingsList.map((setting, idx) => (
                          <tr key={idx} className="text-center">
                            <td>{idx + 1}</td>
                            <td>{setting.setting_initials}</td>
                            <td>
                              <Form.Check
                                type="switch"
                                checked={setting.is_default}
                                onChange={(e) =>
                                  handleChangeDefaultChartSetting(
                                    e,
                                    setting._id,
                                    setting.is_default
                                  )
                                }
                                title={setting.is_default ? "Default" : "Not Default"}
                              />
                            </td>
                            <td>{moment(setting.from_date).format("MMM-DD-YYYY")}</td>
                            <td>{moment(setting.to_date).format("MMM-DD-YYYY")}</td>
                            <td>{setting.user_access}</td>
                            <td>{setting.display_format}</td>
                            <td>
                              <FaSquare style={{ color: setting.chart_color }} />
                              {setting.chart_color}
                            </td>
                            <td>
                              <FaSquare style={{ color: setting.border_color }} />
                              {setting.border_color}
                            </td>
                            <td className="text-center">
                              {activeChartSetting &&
                              activeChartSetting._id === setting._id ? (
                                <FaCheckCircle
                                  className="text-success"
                                  title="Selected"
                                />
                              ) : (
                                <FaRegCheckCircle
                                  className="pointer-cursor"
                                  onClick={(e) =>
                                    getCurrentChartSetting(e, setting._id)
                                  }
                                  title="Click to select"
                                />
                              )}
                              &nbsp;
                              <FaTrashAlt
                                className="text-danger pointer-cursor"
                                onClick={(e) =>
                                  handleDeleteChartSetting(e, setting._id)
                                }
                                title="Delete Chart Setting"
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {chartErrorMessage && (
        <div className="mt-3">
          <h6 className="text-center">{chartErrorMessage}</h6>
        </div>
      )}

      {chartData && (
        <div
          className="w-100 h-100 d-flex justify-content-center"
          style={{ overflowY: "hidden", overflowX: "scroll" }}
        >
          {chartType === "v-bar" ? (
            <Bar
              data={chartData}
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true
                  }
                },
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "User Joined Statistics",
                  },
                },
              }}
            />
          ) : (chartType === "line" || chartType === "area") && (
            <Line
              options={{
                fill: chartType === "area"?true:false,
                scales: {
                  y: {
                    beginAtZero: true
                  }
                },
                tension: 0.3,
                elements: {
                  bar: {
                    borderWidth: 2,
                  },
                },
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "User Joined Statistics",
                  },
                },
              }}
              data={chartData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserSignupsChart;
