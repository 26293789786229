import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideBarOptions from "../SideBarOptions/SideBarOptions";

import "./sidebar.styles.css";

const SideBar = ({ userName, userType, handleLogout }) => {
  return (
    <main className='sidebar-div bg-dark d-md-flex d-lg-flex flex-column justify-content-between d-sm-none d-none' style={{ position: "relative" }}>
      <Navbar
        bg='dark'
        className='p-2 d-flex flex-column align-items-center border-right-1 border-primary'
      >
        <SideBarOptions userName={userName} onClick={handleLogout} userType={userType} />
      </Navbar>

      <span className='p-2 w-100 rounded mb-2 text-center text-light'>
        <div style={{ textTransform: "capitalize" }}>{userType} Portal</div>
        <div style={{ fontSize: "12px" }}><Link to="/privacy_policy" className="text-light" style={{ textDecoration: "none" }}>Privacy Policy</Link></div>
      </span>
    </main>
  );
};

export default SideBar;
