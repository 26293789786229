import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import {
  Row,
  Col,
  Spinner,
  Modal,
  Button,
  Image,
  InputGroup,
  Form,
} from "react-bootstrap";
import {
  FaExclamationTriangle,
  FaPaperPlane,
  FaQuestionCircle,
  FaSave,
} from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import { BASE_URL } from "../../config/config";
// import { LogUserActions } from "../../functionalities/loggedActions";
import WeightBox from "../../components/WeightBox/WeightBox";
import BW_V2_Image from "../../assets/img/blindweight_v2.jpg";
import bwVersionDemo from "../../assets/img/demos/bw_device_version.gif";
import keyCodesImage from "../../assets/img/key_codes.png";

import "./dashboard.styles.css";
import SearchBarComponent from "../../components/SearchBarComponent/SearchBarComponent";
import { motion } from "framer-motion";

const UserRecords = React.lazy(() => import('./UserRecords'));
const ShowWrongInputErrorComponent = React.lazy(() => import('./ShowWrongInputErrorComponent'));

const keyCodes1 = [66, 67, 68, 69, 70, 71, 74, 76, 79, 80, 83, 85];
const keyCodes2 = [98, 99, 100, 101, 102, 103, 106, 108, 111, 112, 115, 117];

const allowedKeyCodes2 = [37, 39, 8, 46, 16, 20]; // right, left arrow keys, backspace, Delete Button, Shift Key, Caps Lock

const invalidUpperCaseCode = ["D", "B"];
// const invalidLowerCaseCode = ["o", "j", "u", "l", "f", "e", "c", "p", "s", "g"];

const Dashboard = () => {
  const [cookies] = useCookies(["blind_weight"]);
  const [authDetails, setAuthDetails] = useState(null);
  const [fetchDataCount, setFetchDataCount] = useState(1);

  const [inputCode, setInputCode] = useState("");
  const [numberData, setNumberData] = useState("");
  const [decoderLoading, setDecoderLoading] = useState(false);

  const [showDeviceVersionError, setShowDeviceVersionError] = useState(false);
  const [showWhatsNew, setShowWhatsNew] = useState(false);
  const [showDecoderValueModal, setShowDecoderValueModal] = useState(false);

  const [showDeviceVersionModal, setShowDeviceVersionModal] = useState(false);
  const [showWrongInputError, setShowWrongInputError] = useState(false);
  const [wrongInputResponseNumber, setWrongInputResponseNumber] = useState("");

  const [welcomePopupHeading, setWelcomePopupHeading] = useState("");
  const [welcomePopupContent, setWelcomePopupContent] = useState("");
  const [welcomePopupContentLoading, setWelcomePopupContentLoading] = useState(false);

  const [saveCodesLoading, setSaveCodesLoading] = useState(false);
  
  const [showEnterUserInitialsModal, setShowEnterUserInitialsModal] = useState(false);
  const [selectedUserInitial, setSelectedUserInitial] = useState("");

  const [deviceVersion, setDeviceVersion] = useState("");

  const [showDecoderNotification, setShowDecoderNotification] = useState(false);
  const [showDecoderNotificationBackdrop, setShowDecoderNotificationBackdrop] =
    useState("");
  const [
    decoderNotificationFooterContent,
    setDecoderNotificationFooterContent,
  ] = useState(null);
  const [removeNotificationLoading, setRemoveNotificationLoading] =
    useState(false);

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  // Create a function to update windowInnerWidth
  const updateWindowInnerWidth = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  // Add an event listener when the component mounts
  useEffect(() => {
    window.addEventListener('resize', updateWindowInnerWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowInnerWidth);
    };
  }, []); // Empty dependency array ensures this effect only runs on mount and unmount


  useEffect(() => {
    if (cookies.blind_weight !== "undefined") {
      // if (savedRecords.length === 0 && authDetails === null) {
      if (authDetails === null) {
        const token = cookies.blind_weight ? cookies.blind_weight : null;

        const decoded = jwt_decode(token);
        setAuthDetails(decoded);
        // LogUserActions(decoded.id, "Dashboard Access");
        // if(decoded.user_type === "user"){
        checkTimesOfLogin(decoded.id, decoded.user_access);
        // }
      }
    }
  }, []);

  useEffect(() => {
    setNumberData("");
  }, [deviceVersion]);

  useEffect(() => {
    if (showDecoderValueModal) {
      if (window.innerWidth >= 768) {
        setShowDecoderValueModal(false);
      }
    }
  }, [showDecoderValueModal]);

  const handleSendInput = async () => {
    // e.preventDefault();
    if (inputCode.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Fields Empty",
        text: "You haven't entered any data to be sent.",
      });
      return;
    }
    if (!(deviceVersion === "v1" || deviceVersion === "v2")) {
      setShowDeviceVersionError(true);
      return;
    }
    try {
      // Conversion of Input to code format STARTS
      let code_to_send = "";
      let inputString = inputCode.toUpperCase();
      for (var i = 0; i < inputString.length; i++) {
        if (invalidUpperCaseCode.includes(inputString[i])) {
          code_to_send += inputString[i].toLowerCase();
        } else {
          code_to_send += inputString[i];
        }
      }
      // Conversion of Input to code format ENDS
      setDecoderLoading(true);
      const response = await axios.post(
        `${BASE_URL}/user/checkCode`,
        {
          input_data: code_to_send,
          device_version: deviceVersion,
        },
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        if (response.data.codeInfo) {
          setNumberData(response.data.codeInfo);
          setDecoderLoading(false);
          setShowDecoderValueModal(true);
          return;
        }
      } else if (response.status === 206) {
        setWrongInputResponseNumber(response.data.codeInfo);
        setShowWrongInputError(true);
        setDecoderLoading(false);
        setNumberData("");
        return;
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
      setDecoderLoading(false);
      return;
    }
  };

  const handleScreenInput = (key) => {
    if (key === "DELETE") {
      if (inputCode.length > 0) {
        setInputCode(inputCode.slice(0, inputCode.length - 1));
      }
      return;
    } else if (key === "CLEAR") {
      setInputCode("");
      return;
    } else if (key === "DECODE") {
      handleSendInput();
      return;
    }
    if (inputCode.length >= 4) {
      Swal.fire({
        icon: "warning",
        title: "Limit ",
        text: "Maximum 4 characters allowed",
      });
      return false;
    }
    setInputCode(inputCode + key);
  };

  const handleOnChangeInput = (e) => {
    setInputCode(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (windowInnerWidth >= 768 && ![37, 39, 8, 46].includes(e.which)) {
      Swal.fire({
        icon: "warning",
        title: "Use OnScreen Keyboard ",
        text: "Please try using the keyboard on the screen.",
      });
      return false;
    }

    if (inputCode.length >= 4 && !allowedKeyCodes2.includes(e.which)) {
      Swal.fire({
        icon: "warning",
        title: "Limit ",
        text: "Maximum 4 characters allowed",
      });
      return false;
    }

    if (
      !allowedKeyCodes2.includes(e.which) &&
      !(keyCodes1.includes(e.which) || keyCodes2.includes(e.which))
    ) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You can enter only the above supported characters",
        imageUrl: keyCodesImage,
        imageWidth: 500,
        // imageHeight: 160,
        imageAlt: "Codes allowed",
      });
      return false;
    }

    return true;
  };
  // RESET entered code values
  const handleResetInput = () => {
    setInputCode("");
    setNumberData("");
  };

  const handleSetUserInitial = (e) => {
    e.preventDefault();
    if (inputCode.length === 0 && numberData.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Fields Empty",
        text: "You haven't entered any data to be saved.",
      });
      return;
    }
    
    // Open Select/Enter User initials
    setShowEnterUserInitialsModal(true);
  }

  const handleSubmitUserInitial = () => {
    setShowDecoderValueModal(false);
    if (selectedUserInitial === "" || selectedUserInitial.length < 2 || selectedUserInitial.length > 20) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'User initials should be between 2 to 20 characters.'
      })
      setShowDecoderValueModal(true);
      return;
    }
    setShowEnterUserInitialsModal(false)

    // If all is okay we can save the data
    handleSaveOutput(selectedUserInitial);
  }

  // SAVE decoded value history
  const handleSaveOutput = async (user_initial) => {
    try {
      if (saveCodesLoading) return;
      setSaveCodesLoading(true);
      const response = await axios.post(
        `${BASE_URL}/user/saveCodeRecords`,
        {
          user_id: authDetails.id,
          code: inputCode,
          value: numberData,
          user_initial,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
        setFetchDataCount((prev) => (prev + 1));
        // RESET entered code values after saving
        handleResetInput();
        setSaveCodesLoading(false);
        setShowDecoderValueModal(false);
        return;
      }
    } catch (error) {
      setSaveCodesLoading(false);
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
      return;
    }
  };

  const fetchUserWelcomePopupData = async(slug) => {
    try {
      if (!slug || welcomePopupContentLoading) return;
      setWelcomePopupContentLoading(true);
      const response = await axios.get(`${BASE_URL}/setting/getPopupSettingBySlugValue/${slug}`);
      if (response.status === 200) {
        // console.log(response);
        if (response.data.popup_data) {
          setWelcomePopupContentLoading(false);
          setWelcomePopupHeading(response.data.popup_data.popup_name);
          setWelcomePopupContent(response.data.popup_data.popup_description);
        }
      }
      
    } catch(e) {
      setWelcomePopupContentLoading(false);
      if (e.response) {
        console.log(e.response.data.message);
      } else {
        console.log(e);
      }
    }
  }

  const checkTimesOfLogin = async (user_id, user_access) => {
    try {
      // if (user_access === "admin") return;
      const response = await axios.get(`${BASE_URL}/user/loginCount`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        // If user_access = "parent/provider" and login_count < 2, then show popup
        // for provider
        if (
          (user_access === "provider" || user_access === "parent") &&
          response.data.showNotifications
        ) {
          if (user_access === "provider") {
            await fetchUserWelcomePopupData('welcome-popup-provider');
          }
          if (user_access === "parent") {
            await fetchUserWelcomePopupData('welcome-popup-parent');
          }

          let footer_html = (
            <>
              <Button
                variant="success"
                as="a"
                href="https://calendly.com/incredible-visibility/5-min-provider-onboarding-call"
                target="_blank"
                size="sm"
              >
                Request a 5-minute demo call
              </Button>
              <Button
                onClick={() => removeDecoderNotifications(user_id)}
                size="sm"
              >
                {removeNotificationLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "I understand how to use the new decoder, don’t show this notification again."
                  // "Stop showing notifications"
                )}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setShowDecoderNotification(false);
                  setShowDecoderNotificationBackdrop("");
                }}
                size="sm"
              >
                Remind me about the new decoder next time again
              </Button>
            </>
          );

          // Check what the previous page is, if it is login page, then show notification
          setShowDecoderNotification(true);
          // setShowDecoderNotificationBackdrop("static");
          setDecoderNotificationFooterContent(footer_html);
        }

        if (
          user_access === "user" &&
          response.data.showNotifications
        ) {
          await fetchUserWelcomePopupData('welcome-popup-user');

          let footer_html = (
            <>
              <Button
                onClick={() => removeDecoderNotifications(user_id)}
                size="sm"
              >
                {removeNotificationLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "I understand how to use the new decoder, don’t show this notification again."
                )}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setShowDecoderNotification(false);
                  setShowDecoderNotificationBackdrop("");
                }}
                size="sm"
              >
                Remind me about the new decoder next time again
              </Button>
            </>
          );

          // Check what the previous page is, if it is login page, then show notification
          setShowDecoderNotification(true);
          // setShowDecoderNotificationBackdrop("static");
          setDecoderNotificationFooterContent(footer_html);
        }
        // If user_access = "parent/provider" and login_count > 2, then show button to stop showing notifications
      }
    } catch (e) {
      if (e.response) {
        console.log(e.response.data.message);
      } else {
        console.log(e.message);
      }
    }
  };

  const removeDecoderNotifications = async (user_id) => {
    try {
      // console.log("API call for removing the notification");
      if (removeNotificationLoading) return;
      setRemoveNotificationLoading(true);
      const response = await axios.post(
        `${BASE_URL}/user/changeUserSetting`,
        {
          user_id: user_id,
          setting_name: "show_decoder_notification",
          setting_value: "false",
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // console.log(response);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Decoder notifications have been disabled",
        });
        setShowDecoderNotificationBackdrop("");
        setShowDecoderNotification(false);
        setRemoveNotificationLoading(false);
      }
    } catch (e) {
      setRemoveNotificationLoading(false);
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.message,
        });
      }
    }
  };

  const renderLoopData = (result) => (
    <div className='ms-2 d-flex flex-column justify-content-center align-items-start'>
      {result.user_initial}
    </div>
  );

  function createWelcomePopupMarkup() {
    return {__html: welcomePopupContent?welcomePopupContent:""};
  }

  return (
    <>
      <Modal
        size="md"
        show={showDeviceVersionModal}
        onHide={() => {
          setShowDeviceVersionModal(false);
        }}
      >
        <Modal.Body>
          <div className="w-100">
            <p style={{ fontSize: "14px" }}>
              If you purchased your scale before 1st Feb, it's likely a{" "}
              <strong>V1</strong> scale.
              <br />
              To check, look for the <strong>"V2"</strong> etched on the glass
              or a label under the scale.
              <br />
              NOTE: <strong>V1</strong> scales do not have any markings.
            </p>
            <Image src={BW_V2_Image} className="img-fluid" />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeviceVersionModal(false);
              }}
              size="sm"
            >
              Close
            </Button>
          </>
        </Modal.Footer>
      </Modal>

      <Suspense fallback={<div className="mt-5"><Spinner animation="border" /></div>}>
        <ShowWrongInputErrorComponent
          show={showWrongInputError}
          hideModal={() => setShowWrongInputError(false)}
          wrongInputResponseNumber={wrongInputResponseNumber}
        />
      </Suspense>

      <Modal
        size="md"
        className="d-block d-sm-block d-md-none d-lg-none"
        show={showDecoderValueModal}
        onHide={() => setShowDecoderValueModal(false)}
      >
        <Modal.Header>Decoder Value</Modal.Header>
        <Modal.Body className="w-100">
          <div className="w-100">
            <p className="text-center">The decoded value in lbs below:</p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                maxLength={4}
                value={numberData}
                className="w-50 p-1 text-center"
                style={{ fontSize: "28px", fontFamily: "Digital-Counter-7" }}
                disabled={true}
              />
              <InputGroup.Text>
                <motion.div whileHover={{ scale: 1.09 }}>
                  {saveCodesLoading ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    <>
                      <div>
                        <FaSave
                          title="Save Output"
                          onClick={handleSetUserInitial}
                        />
                      </div>
                      <div style={{ fontSize: "12px" }}>Save</div>
                    </>
                  )}
                </motion.div>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDecoderValueModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={showDeviceVersionError}
        onHide={() => setShowDeviceVersionError(false)}
      >
        <Modal.Body className="text-center">
          <div className="mb-3">
            <div className="mb-3">
              <AiOutlineExclamationCircle
                className="text-warning"
                style={{ fontSize: "100px" }}
              />
              <h3>Device Version</h3>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <p>
              Please select a valid device version to continue.{" "}
              <FaQuestionCircle
                className="text-primary pointer-cursor"
                style={{ fontSize: "20px" }}
                onClick={() => setShowWhatsNew(true)}
              />
            </p>
            <div className="my-2 text-center px-5">
              <Button
                className="swal2-confirm swal2-styled"
                onClick={() => setShowDeviceVersionError(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal to select/enter User Initails STARTS */}
      <Modal
        size="md"
        show={showEnterUserInitialsModal}
        onHide={() => setShowEnterUserInitialsModal(false)}
      >
        <Modal.Body>
          <div className="mb-3 text-center">
            <div className="mb-3">
              <AiOutlineExclamationCircle
                className="text-warning mb-3"
                style={{ fontSize: "100px" }}
              />
              <h3>User Initials</h3>
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <div className="mb-4">
              <SearchBarComponent
                searchRoute="user/searchUserInitialsList"
                triggerCloseOnSelection={true}
                returnAction={(selected, search_term) => setSelectedUserInitial(search_term)}
                returnSearchTerm={(search_term) => setSelectedUserInitial(search_term)}
                searchPlaceholder="Enter/Search user Initails"
                doNotShowIfNoResults={true}
                renderLoopData={renderLoopData}
                showTermAfterSelection={true}
                value_key="user_initial"
              />
            </div>
            <div className="my-2 text-center px-5">
              <Button
                className="swal2-confirm swal2-styled"
                onClick={() => handleSubmitUserInitial()}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                className="swal2-confirm"
                onClick={() => setShowEnterUserInitialsModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal to select/enter User Initails ENDS */}

      <Modal
        size="md"
        show={showWhatsNew}
        onHide={() => setShowWhatsNew(false)}
      >
        <Modal.Header closeButton>
          <h5 className="p-0 m-0">What's New?</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <span className="mb-3">
              You can change version to decode the codes here.
            </span>
            <br />
            <Image src={bwVersionDemo} className="w-100 rounded" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowWhatsNew(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for showing Notifications STARTS here */}
      <Modal
        size="lg"
        animation="fade"
        // backdrop={
        //   showDecoderNotificationBackdrop
        //     ? showDecoderNotificationBackdrop
        //     : false
        // }
        show={showDecoderNotification}
        onHide={() => {
          setShowDecoderNotification(false);
          setShowDecoderNotificationBackdrop("");
        }}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <h4>
            <FaExclamationTriangle className="text-warning" />
            &nbsp;&nbsp;
            {welcomePopupHeading
              ? welcomePopupHeading
              : "Welcome to Blind Weight Decoder"}
            &nbsp;&nbsp;
            <FaExclamationTriangle className="text-warning" />
          </h4>
        </Modal.Header>

        <Modal.Body style={{ position: "relative" }}>
          <div style={{ fontSize: "14px" }}>
            {welcomePopupContentLoading?(
              <Spinner animation="border" />
            ):(
              <div dangerouslySetInnerHTML={createWelcomePopupMarkup()}></div>
            )}
          </div>
        </Modal.Body>

        {decoderNotificationFooterContent && (
          <Modal.Footer>
            <>{decoderNotificationFooterContent}</>
          </Modal.Footer>
        )}
      </Modal>
      {/* Modal for showing Notifications ENDS here */}

      <main className="px-4 mt-4">
        <Row>
          <Col>
            <p style={{ fontSize: "12px" }}>
              Blind Weight® scale. Our scale utilizes a coded system to capture
              measurement data, providing a secure and confidential way for your
              patients to share their weight measurements with you.
            </p>
          </Col>
        </Row>

        <Row className="text-center pt-2">
          <Col sm={12} md={6} className="d-flex justify-content-center align-items-end">
            <span className="weight-box-head-label">Input Your Code Characters by Simply Clicking or Tapping on the Letter Buttons:</span>
          </Col>
          <Col md={6} className="d-none d-sm-none d-md-flex d-lg-flex justify-content-center align-items-end">
            <span className="weight-box-head-label">The decoded value in lbs below:</span>
          </Col>
        </Row>
        <Row className="weight-decoder text-center pb-2">
          <Col
            sm={12}
            md={6}
            className="p-0 m-0 pe-md-1 pe-lg-2"
          >
            <WeightBox
              bgColor="dark"
              userData={authDetails}
              version={deviceVersion}
              changeVersion={async (v) => {
                if (v === deviceVersion) return;
                const checkSwitch = await Swal.fire({
                  imageUrl: BW_V2_Image,
                  imageHeight: 350,
                  imageAlt: "A tall image",
                  html: `
                    <p style="font-size: 14px;">
                      Are you sure you want to change the decoder to decode <span style="text-transform: capitalize;">${v}</span> scale codes? If you purchased your scale before February 1st, it's likely a V1 scale. To check, look for the "V2" etched on the glass or a label under the scale. V1 scales do not have any markings.
                    </p>
                    `,
                  confirmButtonText: "Confirm",
                  showCancelButton: true,
                });
                if (checkSwitch.isConfirmed) {
                  setDeviceVersion(v);
                }
              }}
              placeholderMessage="____"
              // placeholderMessage='Enter Code'
              inputValue={inputCode}
              inputIconText="Decode"
              inputIcon={<FaPaperPlane title="Send Input" />}
              inputIconLoading={decoderLoading}
              screenInput={(key) => handleScreenInput(key)}
              onChange={handleOnChangeInput}
              iconClick={(e) => {
                e.preventDefault();
                handleSendInput();
              }}
              handleKeyPress={handleKeyPress}
              showKeyboard={true}
              bottomLabel="Scale Decoder"
            />
          </Col>
          <Col
            md={6}
            className="p-0 m-0 ps-md-1 ps-lg-2 d-none d-sm-none d-md-block d-lg-block"
          >
            <WeightBox
              formStatus={true}
              bgColor="success"
              userData={authDetails}
              inputValue={numberData}
              inputIconText="Save"
              inputIcon={<FaSave title="Save Output" />}
              iconClick={handleSetUserInitial}
              placeholderMessage="Results here"
              bottomLabel="Weight Decoded"
            />
          </Col>
        </Row>

        <Suspense fallback={<div className="mt-5"><Spinner animation="border" /></div>}>
          <UserRecords
            authDetails={authDetails}
            fetch_count={fetchDataCount}
          />
        </Suspense>
      </main>
    </>
  );
};

export default Dashboard;
