import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Offcanvas,
  Card,
  ButtonGroup,
  InputGroup,
  Modal,
  Badge,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

import { BASE_UPLOAD_URL, BASE_URL } from "../../config/config";
import "./manage-users.styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  FaEdit,
  FaInfo,
  FaInfoCircle,
  FaPlusCircle,
  FaSearch,
  FaTimes,
  FaTimesCircle,
  FaTrashAlt,
} from "react-icons/fa";
import { LogUserActions } from "../../functionalities/loggedActions";
import moment from "moment";
import MobileNoInput from "../../components/MobileNoInput/MobileNoInput";
import UserSignupsChart from "../../components/ChartGeneration/UserSignupsChart";

// import DataTable from "../../components/DataTable";

const ManageUsers = () => {
  const history = useNavigate();
  const [cookies] = useCookies(["blind_weight"]);
  const [addUserDiv, setAddUserDiv] = useState(false);
  const [adminID, setAdminID] = useState(null);
  const [adminType, setAdminType] = useState(null);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userAccessType, setUserAccessType] = useState("");

  const [userDetails, setUserDetails] = useState([]);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [saveUserLoaded, setSaveUserLoaded] = useState(true);

  const [userExportLoading, setUserExportLoading] = useState(false);

  const [dataOverlayLoading, setDataOverlayLoading] = useState(false);
  const [showUserDataOverlay, setShowUserDataOverlay] = useState(false);
  const [userOverlayData, setUserOverlayData] = useState(null);
  const [userOverlayCreditBalance, setUserOverlayCreditBalance] = useState(10);
  const [updatingUserCreditBalance, setUpdatingUserCreditBalance] =
    useState(false);
  const [showUpdateCreditBalance, setShowUpdateCreditBalance] = useState(false);
  // const [dataTableRange, setDataTableRange] = useState(5);

  const [userOverlayLogData, setUserOverlayLogData] = useState([]);
  const [userOverlayLogDataLoading, setUserOverlayLogDataLoading] =
    useState(false);

  const [fetchUserType, setFetchUserType] = useState("all");
  const [userRange, setUserRange] = useState("recent");
  const [searchTerm, setSearchTerm] = useState("");
  
  const [showUserChartModal, setShowUserChartModal] = useState(false);

    useState(false);

  const handleUserExportFilter = async () => {
    try {
      if (userExportLoading) return;
      setUserExportLoading(true);
      let query_string = "";
      query_string += `user_range=${userRange}&user_type=${fetchUserType}&search_term=${searchTerm}`;
      const response = await axios.get(
        `${BASE_URL}/admin/exportUsersWithFilters?${query_string}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // console.log(response)
        adminID && (await LogUserActions(adminID, "Exported User Details"));
        // setUserDetails(response.data.userData);
        if (response.data.file_url) {
          window.open(`${BASE_UPLOAD_URL}/${response.data.file_url}`, "_blank");
          // window.open("http://www.google.com","_blank");
        }
        setUserExportLoading(false);
      }
    } catch (e) {
      setUserExportLoading(false);
      if (e.response) {
        console.log(e.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleUserFilter = async () => {
    try {
      if (userDetailsLoading) return;
      setUserDetailsLoading(true);
      let query_string = "";
      query_string += `user_range=${userRange}&user_type=${fetchUserType}&search_term=${searchTerm}`;
      const response = await axios.get(
        `${BASE_URL}/admin/getUsersWithFilters?${query_string}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // console.log(response)
        adminID && (await LogUserActions(adminID, "Manage Users Access"));
        setUserDetails(response.data.userData);
        setUserDetailsLoading(false);
      }
    } catch (e) {
      setUserDetailsLoading(false);
      if (e.response) {
        console.log(e.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  useEffect(() => {
    const token =
      cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
    const decoded = jwt_decode(token);
    if (decoded) {
      setAdminID(decoded.id);
      setAdminType(decoded.user_access);
      if (decoded.user_access !== "admin") {
        history("/dashboard");
      } else if (userDetails.length === 0) {
        handleUserFilter();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateUser = () => {
    if (userName.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Name empty",
        text: "Name cannot be empty.",
      });
      return false;
    } else if (userName.length < 3) {
      Swal.fire({
        icon: "error",
        title: "Name too short",
        text: "Name length should be atleast 3 characters.",
      });
      return false;
    } else if (userEmail.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Email empty",
        text: "Email cannot be empty.",
      });
      return false;
    } else if (userMobile.replace(/\D/g, "").length === 0) {
      Swal.fire({
        icon: "error",
        title: "Mobile No empty",
        text: "Please enter user mobile number.",
      });
      return false;
    } else if (userMobile.replace(/\D/g, "").length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Mobile No Invalid",
        text: "Mobile number should be a 10 digits number.",
      });
      return false;
    } else if (userAccessType === "") {
      Swal.fire({
        icon: "error",
        title: "User Access empty",
        text: "Please select any one user type",
      });
      return false;
    }
    return true;
  };

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    if (validateUser()) {
      try {
        if (userAccessType === "admin") {
          const deleteUserWarning = await Swal.fire({
            icon: "warning",
            title: `Are you sure to set user to Admin?`,
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
          });
          if (deleteUserWarning.isDenied) {
            return;
          }
        }
        setSaveUserLoaded(false);
        const response = await axios.post(
          `${BASE_URL}/admin/createUser`,
          {
            name: userName.trim(),
            email: userEmail.trim().toLowerCase(),
            mobile_no: userMobile.replace(/\D/g, ""),
            user_access: userAccessType,
          },
          {
            withCredentials: true,
          },
          {
            headers: {
              Cookie: cookies.blind_weight,
            },
          }
        );
        if (response.status === 200) {
          // console.log(response);
          await LogUserActions(adminID, "Create New User");
          handleUserFilter();
          setSaveUserLoaded(true);
          // RESET input form values
          setUserName("");
          setUserEmail("");
          setUserMobile("");
          setUserAccessType("");

          Swal.fire({
            icon: "success",
            title: "User Added",
            text: response.data.message,
          });
        }
      } catch (error) {
        setSaveUserLoaded(true);
        if (error.response) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          });
        } else {
          console.log(error.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message,
          });
        }
        return;
      }
    }
  };

  // function for delete user (Yet to be implemented)
  const handleDeleteUser = async (e, user_id, name) => {
    e.preventDefault();
    try {
      const deleteUserWarning = await Swal.fire({
        title: `Are you sure to delete this user:: ${name} ?`,
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      });
      if (deleteUserWarning.isDenied) {
        return;
      }
      if (deleteUserWarning.isConfirmed) {
        // API call to delete the user
        const response = await axios.get(
          `${BASE_URL}/admin/deleteUser/${user_id}`,
          {
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          await LogUserActions(adminID, `Delete User:: ${name}`);
          // console.log(response)
          handleUserFilter();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          return;
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
      return;
    }
  };

  const handleToggleStatus = async (e, user_id) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        `${BASE_URL}/admin/toggleUserStatus`,
        {
          user_id,
        },
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        handleUserFilter();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleFetchUserDetails = async (e, user_id) => {
    try {
      e.preventDefault();
      if (dataOverlayLoading) return;
      setDataOverlayLoading(true);
      const response = await axios.post(
        `${BASE_URL}/user/fetchAllUserDetails`,
        {
          user_id,
        },
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );

      if (response.status === 200) {
        // console.log(response);
        console.log(response.data.userDetails);
        setShowUserDataOverlay(true);
        handleFetchUserLogDetails(user_id);
        setUserOverlayData(response.data.userDetails);
        setDataOverlayLoading(false);
        // Swal.fire({
        //   icon: "success",
        //   title: "Success",
        //   text: response.data.message,
        // });
      }
    } catch (e) {
      setDataOverlayLoading(false);
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleFetchUserLogDetails = async (user_id) => {
    try {
      if (userOverlayLogDataLoading) return;
      setUserOverlayLogDataLoading(true);
      const response = await axios.post(
        `${BASE_URL}/admin/fetchRecentLogs`,
        {
          user_id,
        },
        {
          withCredentials: true,
        },
        {
          headers: {
            Cookie: cookies.blind_weight,
          },
        }
      );

      if (response.status === 200) {
        setUserOverlayLogData(response.data.loggedData)
        setUserOverlayLogDataLoading(false);
      }
    } catch (e) {
      setUserOverlayLogDataLoading(false);
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      handleUserFilter(e);
    }
  };


  const handleAddCreditToUser = async (e, user_id) => {
    e.preventDefault();
    if (!user_id) return;
    if (!userOverlayCreditBalance) return;
    if (updatingUserCreditBalance) return;
    const warning = await Swal.fire({
      icon: "warning",
      title: "Warning",
      text: "Do you Confirm to add credit balance to user?",
      showDenyButton: true,
      confirmButtonText: "Confirm",
      denyButtonText: "No",
    });

    if (!warning.isConfirmed) return;
    // console.log('userOverlayCreditBalance: ',userOverlayCreditBalance);
    try {
      setUpdatingUserCreditBalance(true);
      const response = await axios.post(
        `${BASE_URL}/admin/updateCreditBalanceOfUser`,
        {
          user_id: user_id,
          credit_balance: userOverlayCreditBalance,
          action_type: "add",
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        // console.log(response);
        handleFetchUserDetails(e, user_id);
        setUpdatingUserCreditBalance(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      setUpdatingUserCreditBalance(false);
      if (e.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
    }
  };

  const creditBalancePopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">User credit balance</Popover.Header>
      <Popover.Body>
        User credit balance is assigned to provider or parent so, they can
        decode the code if they opt-in for decoding using messaging services.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Offcanvas
        placement="end"
        show={showUserDataOverlay}
        onHide={() => setShowUserDataOverlay(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>User Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ overflowX: "hidden", overflowY: "scroll" }}>
          {userOverlayData && (
            <>
              <div>Name: {userOverlayData.name}</div>
              <div>Email: {userOverlayData.email}</div>
              <div>Mob.No: {userOverlayData.mobile_no}</div>
              <div>User Type: {userOverlayData.user_access}</div>
              <div>
                Joined On:{" "}
                {moment(userOverlayData.createdAt).format("MM-DD-YYYY")}
              </div>
              {(userOverlayData.user_access === "provider" ||
                userOverlayData.user_access === "parent") && (
                <Card className="my-2 p-2">
                  <div className="mb-2 relative">
                    <span>
                      User Credit Balance:{" "}
                      {userOverlayData.credit_data.length === 0
                        ? "$0"
                        : `$${userOverlayData.credit_data[0].credit_balance}`}
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                      }}
                      className="d-flex justify-content-evenly"
                    >
                      <div className="me-1 d-flex justify-content-center align-items-center">
                        {showUpdateCreditBalance ? (
                          <FaTimesCircle
                            className="pointer-cursor text-danger"
                            style={{ fontSize: "28px" }}
                            title="Close add credit div"
                            onClick={() => setShowUpdateCreditBalance(false)}
                          />
                        ) : (
                          <FaPlusCircle
                            className="pointer-cursor text-primary"
                            style={{ fontSize: "28px" }}
                            title="Open add credit div"
                            onClick={() => setShowUpdateCreditBalance(true)}
                          />
                        )}
                      </div>
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        overlay={creditBalancePopover}
                      >
                        <Button
                          variant="info"
                          style={{ height: "30px", width: "30px" }}
                          className="d-flex justify-content-center align-items-center text-dark rounded-circle"
                          title="What is Credit Balance?"
                        >
                          <FaInfo size="sm" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  {showUpdateCreditBalance && (
                    <div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          min={1}
                          value={userOverlayCreditBalance}
                          onChange={(e) =>
                            setUserOverlayCreditBalance(e.target.value)
                          }
                          placeholder="Input credit balance"
                        />
                        <InputGroup.Text>
                          <Button
                            onClick={(e) =>
                              handleAddCreditToUser(e, userOverlayData._id)
                            }
                            disabled={updatingUserCreditBalance}
                          >
                            {updatingUserCreditBalance ? (
                              <Spinner size="sm" />
                            ) : (
                              "Add Credit"
                            )}
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  )}
                </Card>
              )}
              <Card className="my-2 p-2">
                <span className="mb-1">
                  <strong>Terms Data:</strong>
                </span>
                <div>
                  {userOverlayData.terms_data.length === 0 ? (
                    <span>User has not accepted terms.</span>
                  ) : (
                    <>
                      <div>
                        Terms Accepted:{" "}
                        {userOverlayData.terms_data[0].terms ? "Yes" : "No"}
                      </div>
                      <div>
                        Updated IP:{" "}
                        {userOverlayData.terms_data[0].current_ip_address.startsWith(
                          "::ffff:"
                        )
                          ? userOverlayData.terms_data[0].current_ip_address.slice(
                              7
                            )
                          : userOverlayData.terms_data[0].current_ip_address}
                      </div>
                      <div>
                        Last Updated On:{" "}
                        {moment(userOverlayData.terms_data[0].updatedAt).format(
                          "MM-DD-YYYY"
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Card>
              {userOverlayData.user_access === "parent" && (
                <>
                  <Card className="my-2 p-2">
                    <span className="mb-1">
                      <strong>Payment Data:</strong>
                    </span>
                    <div>
                      {userOverlayData.pay_data.length === 0 ? (
                        <span>User has not done payment.</span>
                      ) : (
                        <>
                          <div>
                            Payment Status:{" "}
                            {userOverlayData.pay_data[0].payment_status}
                          </div>
                          <div>
                            Paid On:{" "}
                            {moment(
                              userOverlayData.pay_data[0].createdAt
                            ).format("MM-DD-YYYY")}
                          </div>
                        </>
                      )}
                    </div>
                  </Card>
                </>
              )}
              <Card className="my-2 p-2">
                <span className="mb-1">
                  <strong>User Logs:</strong>
                </span>
                <div>
                  {userOverlayLogDataLoading ? (
                    <Spinner animation="border" />
                  ) : userOverlayLogData.length === 0 ? (
                    <span>No User Logs recorded.</span>
                  ) : (
                    userOverlayLogData.map((log, idx) => (
                      <div key={idx}>
                        {log.log_message}{" "}
                        <Badge>
                          {moment(log.createdAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )}
                        </Badge>
                      </div>
                    ))
                  )}
                </div>
              </Card>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        id="chartSettingModal"
        size="xl"
        backdrop={true}
        scrollable={true}
        show={showUserChartModal}
        onHide={() => setShowUserChartModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body style={{ position: "relative" }}>

          <UserSignupsChart changeParentModalStatus={(status) => setShowUserChartModal(status)} />

        </Modal.Body>

        {/* <Modal.Footer>
        </Modal.Footer> */}
      </Modal>

      <main className="px-4 mb-4 justify-content-center">
        {/* <Button onClick={handleSaveChartSetting}>Test</Button> */}
        <Row className="m-2">
          <Col className="pt-3 d-flex justify-content-end">
            <span
              className={`p-2 text-light ${
                addUserDiv ? "bg-danger rounded-circle" : "bg-primary rounded"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => setAddUserDiv(!addUserDiv)}
            >
              {addUserDiv ? (
                <FaTimes size={25} className="p-0 m-0" />
              ) : (
                "Add User"
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col
            md={12}
            className={`my-2 border ${addUserDiv ? "d-block" : "d-none"}`}
          >
            <h2 className="text-center">Add Users</h2>
            <Row className="p-3">
              <Form.Group as={Col} md={6} className="mb-2">
                <Form.Label>User Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Enter User name"
                />
              </Form.Group>
              <Form.Group as={Col} md={6} className="mb-2">
                <Form.Label>User Email:</Form.Label>
                <Form.Control
                  type="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="Enter User email"
                />
              </Form.Group>
              <Form.Group as={Col} md={6} className="mb-2">
                <Form.Label>User Mobile Number:</Form.Label>
                {/* <Form.Control
                  type="tel"
                  value={userMobile}
                  onChange={(e) => {
                    const { value } = e.target;
                    // Replace any non-numeric characters with an empty string
                    const sanitizedValue = value.replace(/[^0-9]/g, '');
                    setUserMobile(sanitizedValue);
                  }}
                  placeholder="Enter 10 digit user mobile number"
                /> */}
                <MobileNoInput
                  value={userMobile}
                  onChange={(e) => setUserMobile(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className="mb-3">
                <Form.Label>Select User Access Type:</Form.Label>
                <Form.Select
                  value={userAccessType}
                  onChange={(e) => setUserAccessType(e.target.value)}
                >
                  <option value="">Select User Access type...</option>
                  <option value="superadmin">SuperAdmin</option>
                  <option value="admin">Admin</option>
                  <option value="provider">Clinician/Provider</option>
                  <option value="parent">Parent</option>
                  <option value="user">User</option>
                </Form.Select>
              </Form.Group>
              <Button onClick={handleSubmitUser}>
                {saveUserLoaded ? (
                  "Invite User"
                ) : (
                  <Spinner size="sm" animation="border" />
                )}
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} sm={12} md={6} lg={4} className="my-2">
            <div>Select User type:</div>
            <ButtonGroup>
              <Button
                size="sm"
                variant={`${fetchUserType === "all" ? "primary" : "secondary"}`}
                onClick={() => setFetchUserType("all")}
              >
                All
              </Button>
              <Button
                size="sm"
                variant={`${
                  fetchUserType === "provider" ? "primary" : "secondary"
                }`}
                onClick={() => setFetchUserType("provider")}
              >
                Provider
              </Button>
              <Button
                size="sm"
                variant={`${
                  fetchUserType === "parent" ? "primary" : "secondary"
                }`}
                onClick={() => setFetchUserType("parent")}
              >
                Parent
              </Button>
              <Button
                size="sm"
                variant={`${
                  fetchUserType === "user" ? "primary" : "secondary"
                }`}
                onClick={() => setFetchUserType("user")}
              >
                User
              </Button>
            </ButtonGroup>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="my-2">
            <div>Select Range:</div>
            <ButtonGroup>
              <Button
                size="sm"
                variant={`${userRange === "recent" ? "primary" : "secondary"}`}
                onClick={() => setUserRange("recent")}
              >
                Recent
              </Button>
              <Button
                size="sm"
                variant={`${
                  userRange === "this_month" ? "primary" : "secondary"
                }`}
                onClick={() => setUserRange("this_month")}
              >
                Last month
              </Button>
              <Button
                size="sm"
                variant={`${userRange === "all" ? "primary" : "secondary"}`}
                onClick={() => setUserRange("all")}
              >
                All
              </Button>
            </ButtonGroup>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="my-2">
            <div>Search Text:</div>
            <InputGroup>
              <Form.Control
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchKeyDown}
                placeholder="Enter your search term"
              />
              <InputGroup.Text
                className="pointer-cursor"
                onClick={handleUserFilter}
                title="Search"
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className="my-2">
            <Button className="me-2" variant="warning" onClick={handleUserFilter}>
              {userDetailsLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Apply Filters"
              )}
            </Button>
            <Button className="me-2" onClick={handleUserExportFilter}>
              {userExportLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Export Users"
              )}
            </Button>
            <Button
              className="me-2"
              onClick={(e) => {
                setShowUserChartModal(true);
              }}
            >
              Show User Chart
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table bordered striped responsive>
              <thead className="text-center">
                <tr>
                  <th>Sl.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>User Type</th>
                  <th>Mobile</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {userDetailsLoading ? (
                  <tr className="text-center m-3">
                    <td colSpan={6}>
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : userDetails.length === 0 ? (
                  <tr>
                    <td colSpan={5}>No Data Exists</td>
                  </tr>
                ) : (
                  userDetails.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.user_access}</td>
                      {/* <td>{item.mobile_no}</td> */}
                      <td>
                        {item.mobile_no
                          .replace(/\D/g, "")
                          .slice(-10)
                          .replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3")}
                      </td>
                      <td>
                        <span title="user status">
                          {item.status === "invited" ? (
                            "invited"
                          ) : (
                            <Form.Check
                              type="switch"
                              onChange={(e) => handleToggleStatus(e, item._id)}
                              checked={item.status === "active" ? true : false}
                            />
                          )}
                        </span>
                      </td>
                      <td>
                        <FaInfoCircle
                          className="pointer-cursor"
                          title="User Info"
                          onClick={(e) => handleFetchUserDetails(e, item._id)}
                        />
                        &nbsp;
                        <Link to={`/edit-user/${item._id}`}>
                          <FaEdit title="Edit" color="rgb(80,80,255)" />
                        </Link>
                        &nbsp;
                        <FaTrashAlt
                          className="pointer-cursor"
                          onClick={(e) =>
                            handleDeleteUser(
                              e,
                              item._id,
                              item.name,
                              item.user_access
                            )
                          }
                          title="Delete"
                          color="rgb(255,80,80)"
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </main>
    </>
  );
};

export default ManageUsers;
