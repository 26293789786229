import React, { forwardRef, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaUsers, FaSignInAlt, FaStream } from "react-icons/fa";

import logo from "../../assets/img/BW-Logo.png";
import "./headingnavbar.styles.css";
import SideBarOptions from "../SideBarOptions/SideBarOptions";

const HeadingNavbar = ({ userName, userType, onClick }, ref) => {
  return (
    <main ref={ref} style={{ width: "100%", position: "sticky", top: "0", zIndex: "5" }}>
      <Navbar expand="md" className='heading-navbar' sticky="top">
        <Container className='mx-md-0 px-md-4'>
          <Navbar.Brand as={Link} to='/dashboard'>
            <Image
              src={logo}
              style={{ width: "100px" }}
              className='img-fluid bg-light p-2 rounded'
            />
          </Navbar.Brand>
          <Navbar.Toggle
            className='bg-light'
            aria-controls='basic-navbar-nav'
          />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='d-lg-none d-block d-sm-block d-md-none'>
              <SideBarOptions userName={userName} onClick={onClick} userType={userType} />
              <span className='w-100 rounded mb-2 text-center text-light'>
                <div style={{ textTransform: "capitalize" }}>{userType} Portal</div>
                <div style={{ fontSize: "12px" }}><Link to="/privacy_policy" className="text-light" style={{ textDecoration: "none" }}>Privacy Policy</Link></div>
              </span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </main>
  );
};

export default forwardRef(HeadingNavbar);
