import React, { Suspense } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";

const UserSignupsChart = React.lazy(() => import('../../components/ChartGeneration/UserSignupsChart'));
const UserProgressChart = React.lazy(() => import('../../components/ChartGeneration/UserProgressChart'));
const UserActivityChart = React.lazy(() => import('../../components/ChartGeneration/UserActivityChart'));

const UserStats = () => {
  return (
    <main className="px-4 mb-4 justify-content-center">
      <Row className="p-2">
        <Col className="pt-3 d-flex">
          <h3>User Statistics</h3>
        </Col>
      </Row>
      <Row className="p-2 w-100">
        <Col md={6} sm={12} className="w-100">
          <Tabs
            defaultActiveKey="user_signups"
            id="user_stats_tabs_area"
            className="mb-3"
          >
            <Tab eventKey="user_signups" title="User Signups">
              {/* Create chart for User Signups */}
              <div className="w-100 px-2">
                <Suspense fallback={<div><LoaderSpinner /></div>}>
                  <UserSignupsChart />
                </Suspense>
              </div>
            </Tab>
            <Tab eventKey="user_progress" title="User Progress">
              {/* Create chart for User Progress in weight loss */}
              <div className="w-100">
                <Suspense fallback={<div><LoaderSpinner /></div>}>
                  <UserProgressChart />
                </Suspense>
              </div>
            </Tab>
            <Tab eventKey="user_activity" title="User Activity">
              {/* Create chart for User Activity in the portal */}
              <div className="w-100">
                <Suspense fallback={<div><LoaderSpinner /></div>}>
                  <UserActivityChart />
                </Suspense>
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </main>
  );
};

export default UserStats;
