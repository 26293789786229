import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Col, Form, FormGroup, Row, Spinner } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { BASE_URL } from "../../config/config";
import Swal from "sweetalert2";


const ManageTerms = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies("blind_weight");

  const [termsID, setTermsID] = useState("");
  const [termsHeading, setTermsHeading] = useState("");
  const [termsContentText, setTermsContentText] = useState("");
  const [termsLoading, setTermsLoading] = useState(false);

  const [invalidTermHeading, setInvalidTermHeading] = useState(false);
  const [invalidTermHeadingMessage, setInvalidTermHeadingMessage] =
    useState("");
  const [invalidTermContent, setInvalidTermContent] = useState(false);
  const [invalidTermContentMessage, setInvalidTermContentMessage] =
    useState("");

  const handleFetchTerms = async () => {
    try {
      if (termsLoading) return;
      setTermsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/terms/getTermsAndConditions`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // console.log(response);
        if (response.data.termDetails) {
          setTermsID(response.data.termDetails._id);
          setTermsHeading(response.data.termDetails.terms_heading);
          setTermsContentText(response.data.termDetails.terms_content_text);
        }
        setTermsLoading(false);
      }
    } catch (e) {
      setTermsLoading(false);
      if (e.response) {
        console.log(e.response);
      } else {
        console.log(e);
      }
    }
  };
  
  useEffect(() => {
    const token =
      cookies.blind_weight !== "undefined" ? cookies.blind_weight : null;
    const decoded = jwtDecode(token);
    if (decoded) {
      // setAdminID(decoded.id);
      // setAdminType(decoded.user_access);
      if (decoded.user_access !== "admin") {
        navigate("/dashboard");
      } else {
        handleFetchTerms();
      }
    }
  }, []);

  const inputHandler = (e) => {
    // console.log(e.editor.getData());
    // let editorData = e.editor.getData();
    setTermsContentText(e.editor.getData());
  };

  const validTermParams = () => {
    let status = true;

    if (termsHeading === "") {
      setInvalidTermHeading(true);
      setInvalidTermHeadingMessage("Please enter Terms Heading");
      status = false;
    } else if (termsHeading.length < 5) {
      setInvalidTermHeading(true);
      setInvalidTermHeadingMessage(
        "Terms Heading should be atleast 5 characters in length"
      );
      status = false;
    } else {
      setInvalidTermHeading(false);
    }

    // console.log(termsContentText);
    if (termsContentText === "") {
      setInvalidTermContent(true);
      setInvalidTermContentMessage("Please enter Terms Content");
      status = false;
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter Terms Content",
      });
    } else if (termsContentText.length < 10) {
      setInvalidTermContent(true);
      setInvalidTermContentMessage(
        "Terms Content should be atleast 10 characters in length"
      );
      status = false;
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Terms Content should be atleast 10 characters in length",
      });
    } else {
      setInvalidTermContent(false);
    }

    return status;
  };

  const handleSubmitTermsData = async (e) => {
    try {
      e.preventDefault();
      if (!validTermParams()) return;
      if (termsLoading) return;
      setTermsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/terms/updateTermsAndConditions`,
        {
          terms_id: termsID,
          terms_heading: termsHeading,
          terms_content_text: termsContentText,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // console.log(response);
        setTermsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    } catch (e) {
      setTermsLoading(false);
      if (e.response) {
        console.log(e.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong.",
        });
      }
    }
  };

  return (
    <>
      <main className="px-4 mb-4 justify-content-center">
        <Row className="p-2">
          <Col className="pt-3 d-flex">
            <h3>Manage Terms &amp; Conditions</h3>
          </Col>
        </Row>
        <Row>
          <Col className="pt-3">
            <FormGroup className="mb-2">
              <Form.Label>Term Heading:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={invalidTermHeading}
                value={termsHeading}
                onChange={(e) => setTermsHeading(e.target.value)}
                placeholder="Enter terms heading"
              />
              {invalidTermHeading && (
                <Form.Control.Feedback type="invalid">
                  {invalidTermHeadingMessage}
                </Form.Control.Feedback>
              )}
            </FormGroup>

            <FormGroup className="mb-2">
              <Form.Label>Term Content:</Form.Label>

              <CKEditor
                editor={ Editor }
                config={ {
                  height: "100px",
                  // toolbar: [
                  //   'undo', 'redo',
                  //   '|', 'heading',
                  //   '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                  //   '|', 'alignment',
                  //   '|', 'blockQuote', 'codeBlock',
                  //   '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                  // ],
                } }
                data={termsContentText}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    // console.log( { event, editor, data } );
                    setTermsContentText(data);
                } }
                onBlur={ ( event, editor ) => {
                  // console.log( 'Blur.', editor );
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  setTermsContentText(data);
                } }
                onFocus={ ( event, editor ) => {
                  // console.log( 'Focus.', editor );
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  setTermsContentText(data);
                } }
              />
            </FormGroup>

            <Button className="my-2" onClick={handleSubmitTermsData}>
              {termsLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </main>
    </>
  );
};

export default ManageTerms;
